import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { noneCoffee } from '../../const/menu/noneCoffee/noneCoffee';
import { signature } from '../../const/menu/coffee/signature';
import { coffee } from '../../const/menu/coffee/coffee';
import { coldBrew } from '../../const/menu/coffee/coldbrew';
import { bottle } from '../../const/menu/bottle/bottle';
import { dessert } from '../../const/menu/noneCoffee/dessert';
import { TeaBlackTea } from '../../const/menu/noneCoffee/TeaBlackTea';
import { bubbleTeaJuice } from '../../const/menu/noneCoffee/bubbleTeaJuice';
import { blended } from '../../const/menu/noneCoffee/blended';

export function Allmenu() {
  const { id } = useParams();
  const [menuData, setMenuData] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);

  // const [menuData, setMenuData] = useState({
  //   title: '1L보틀',
  //   text: '1L Bottle (only ICE)',
  //   contents: bottle,
  // });
  // const [activeMenu, setActiveMenu] = useState('bottle');
  useEffect(() => {
    if (id && menuItems[id]) {
      setMenuData(menuItems[id]);
      setActiveMenu(id);
    } else {
      setMenuData(menuItems.bottle);
      setActiveMenu('bottle');
      // id에 해당하는 메뉴가 없는 경우 처리
      // 예: 기본 페이지로 리다이렉트하거나 에러 메시지 표시
    }
    // eslint-disable-next-line
  }, [id]);

  const menuItems = {
    bottle: {
      title: '1L보틀',
      text: '1L Bottle (only ICE)',
      contents: bottle,
    },
    signature: {
      title: '시그니처',
      text: 'Signature (only ICE)',
      contents: signature,
    },
    coffee: {
      title: '커피',
      text: 'Coffee (HOT/ICE)',
      contents: coffee,
    },
    noneCoffee: {
      title: '넌커피',
      text: 'None Coffee (HOT/ICE)',
      contents: noneCoffee,
    },
    blended: {
      title: '블렌디드',
      text: 'Blended (only ICE)',
      contents: blended,
    },
    blackTeaTea: {
      title: '블랙티/티',
      text: 'Black Tea & Tea (HOT/ICE)',
      contents: TeaBlackTea,
    },
    coldBrewDeCaffeination: {
      title: '콜드브루/디카페인',
      text: 'ColdBrew (only ICE)',
      contents: coldBrew,
    },
    bubbleTeaJuice: {
      title: '버블티/주스',
      text: 'Bubble Tea & Juice (only ICE)',
      contents: bubbleTeaJuice,
    },
    dessert: {
      title: '디저트',
      text: 'Dessert',
      contents: dessert,
    },
  };

  const updateMenuData = (menuItemKey) => {
    setMenuData(menuItems[menuItemKey]);
    setActiveMenu(menuItemKey);
  };

  return (
    <div className="allMenu">
      <div className="brand-title">
        <img src="/img/tab-menu1.png" alt="" />
        <div className="ment">
          <strong>메뉴</strong>
          {/* <p>합리적인 가격과 높은 품질의 커피</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/newmenu">신메뉴</Link>
              </li>
              <li className="on">
                <Link to="/allMenu">전체메뉴</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner2">
        <div className="section4">
          <span>
            홈 {'>'} 메뉴소개 {'>'} 전체메뉴
          </span>
        </div>
        <div className="section2">
          <div className="select">
            <button
              className={activeMenu === 'bottle' ? 'active' : ''}
              onClick={() => updateMenuData('bottle')}
            >
              1L보틀
            </button>

            <button
              className={activeMenu === 'signature' ? 'active' : ''}
              onClick={() => updateMenuData('signature')}
            >
              시그니처
            </button>
            <button
              className={activeMenu === 'coffee' ? 'active' : ''}
              onClick={() => updateMenuData('coffee')}
            >
              커피
            </button>
            <button
              className={activeMenu === 'noneCoffee' ? 'active' : ''}
              onClick={() => updateMenuData('noneCoffee')}
            >
              넌커피
            </button>
            <button
              className={activeMenu === 'blended' ? 'active' : ''}
              onClick={() => updateMenuData('blended')}
            >
              블렌디드
            </button>
            <button
              className={activeMenu === 'blackTeaTea' ? 'active' : ''}
              onClick={() => updateMenuData('blackTeaTea')}
            >
              블랙티/티
            </button>
            <button
              className={
                activeMenu === 'coldBrewDeCaffeination' ? 'active' : ''
              }
              onClick={() => updateMenuData('coldBrewDeCaffeination')}
            >
              콜드브루/디카페인
            </button>
            <button
              className={activeMenu === 'bubbleTeaJuice' ? 'active' : ''}
              onClick={() => updateMenuData('bubbleTeaJuice')}
            >
              버블티/주스
            </button>
            <button
              className={activeMenu === 'dessert' ? 'active' : ''}
              onClick={() => updateMenuData('dessert')}
            >
              디저트
            </button>
          </div>
        </div>
        <div className="section3">
          {/* <div className="sub-title">
            <strong>{menuData.title}</strong>
            <p>{menuData.text}</p>
          </div> */}
          <div className="content-wrap">
            {menuData?.contents?.map((item) => (
              <div className="content" key={item.id}>
                <img src={item.imgUrl} alt="" />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
