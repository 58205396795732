// import React, { useState } from "react";

import { useState } from 'react';
import { createInquiry } from '../../hooks/createInquiry';
import { Link } from 'react-router-dom';
import { AgreeModal } from '../../components/agreeModal';
import { isAgreeModalOpenState } from '../../recoil/atom';
import { useRecoilState } from 'recoil';
import { sendEmail } from '../../hooks/sendEmail';

export function Inquiry() {
  // 폼 상태를 관리할 state를 설정합니다.
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    location: '',
    content: '',
  });
  const [dataCollectionAgreement, setDataCollectionAgreement] = useState(false);

  const [isAgreeModalOpen, setIsAgreeModalOpen] = useRecoilState(
    isAgreeModalOpenState
  );

  const openAgreeModal = () => setIsAgreeModalOpen(true);
  const closeAgreeModal = () => setIsAgreeModalOpen(false);

  // 각 input의 변경사항을 state에 반영하는 함수입니다.
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //전화번호 입력 폼
  const handleFormInputChange2 = (e) => {
    const { name, value } = e.target;
    let formattedValue = value.replace(/\D/g, ''); // 숫자만 남김

    if (formattedValue.length > 3 && formattedValue.length <= 7) {
      formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
        3
      )}`;
    } else if (formattedValue.length > 7) {
      formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
        3,
        7
      )}-${formattedValue.slice(7, 11)}`;
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // 개인정보 수집 동의 체크박스 변경 핸들러
  const handleDataCollectionAgreementChange = (event) => {
    setDataCollectionAgreement(event.target.checked);
  };

  // 폼을 제출할 때 호출되는 함수입니다.
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.phone ||
      !formData.location ||
      formData.phone.length < 13
    ) {
      alert('이름, 전화번호, 지역을 모두 입력해 주세요.');
      return;
    }

    if (!dataCollectionAgreement) {
      alert('개인정보 수집에 동의 부탁드립니다.');
      return;
    }

    // 콘솔에 폼 데이터를 출력합니다.
    // console.log('Form Data:', formData);
    createInquiry(
      formData.name,
      formData.phone,
      formData.location,
      formData.content
    );

    sendEmail(
      formData.name,
      formData.phone,
      formData.location,
      formData.content
    );

    alert('감사합니다.');
  };
  return (
    <div className="inquiry">
      <div className="brand-title">
        <img src="/img/tab-franchise1.png" alt="" />
        <div className="ment">
          <strong>가맹안내</strong>
          {/* <p>너도 창업 할 수 있다</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/procedure">가맹절차</Link>
              </li>
              <li>
                <Link to="/cost">개설비용</Link>
              </li>
              <li>
                <Link to="/advantage">경쟁력</Link>
              </li>
              <li className="on">
                <Link to="/inquiry">가맹문의</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>이공커피 가맹문의</strong>
        </div>
        {/* <div className="section2">
          <div>
            <img src="/img/maps-and-flags.png" alt="" />
            <h3>Adress</h3>
            <div></div>
            <p>서울시 동작구 서달로151, 이공커피</p>
          </div>
          <div>
            <img src="/img/phone-call.png" alt="" />
            <h3>TEL</h3>
            <div></div>
            <p>1533-4263</p>
          </div>
          <div>
            <img src="/img/email.png" alt="" />
            <h3>EMAIL</h3>
            <div></div>
            <p>office@yigongcoffee.com</p>
          </div>
        </div> */}
        <div className="section3">
          <form className="frm" id="frm" onSubmit={handleSubmit}>
            <div className="con con02">
              <div className="inner">
                <div className="f_wrap animation">
                  <ul>
                    <li>
                      <label>이름</label>
                      <div className="d_wr">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="이름을 입력해주세요"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </li>
                    <li>
                      <label>연락처</label>
                      <div className="d_wr">
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="연락처"
                          value={formData.phone}
                          onChange={(handleInputChange, handleFormInputChange2)}
                        />
                      </div>
                    </li>
                    <li>
                      <label>희망 지역</label>
                      <div className="d_wr">
                        <select
                          className="counselCompany"
                          name="location"
                          value={formData.location}
                          onChange={handleInputChange}
                        >
                          <option value="defalut">지역을 선택해주세요</option>
                          <option>서울</option>
                          <option>인천</option>
                          <option>경기</option>
                          <option>강원</option>
                          <option>충북</option>
                          <option>충남</option>
                          <option>대전</option>
                          <option>경북</option>
                          <option>경남</option>
                          <option>부산</option>
                          <option>울산</option>
                          <option>대구</option>
                          <option>전북</option>
                          <option>전남</option>
                          <option>광주</option>
                          <option>제주</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <label>문의 내용</label>
                      <div className="d_wr">
                        <textarea
                          name="content"
                          id="content"
                          placeholder="문의내용을 입력해주세요"
                          value={formData.content}
                          onChange={handleInputChange}
                        />
                      </div>
                    </li>
                  </ul>
                  <div className="chk_w">
                    <div className="chk">
                      <input
                        type="checkbox"
                        checked={dataCollectionAgreement}
                        onChange={handleDataCollectionAgreementChange}
                      />
                      <p>
                        개인정보 수집 및 마케팅 활용 동의
                        <span onClick={openAgreeModal}> [내용보기]</span>
                      </p>
                    </div>
                  </div>
                  <div className="submit">
                    <button type="submit">
                      <p>문의하기</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <AgreeModal isOpen={isAgreeModalOpen} onClose={closeAgreeModal} />
    </div>
  );
}
