import React from 'react';

export function Footer() {
  return (
    <div className="footer">
      <div className="section1">
        <h3>이공컴퍼니</h3>
        <p>대표 이민규 | 사업자등록번호 : 409-42-69244</p>
        <p>주소 : 경기도 고양시 덕양구 지정로 15, 301, 302호 이공컴퍼니</p>
        <p>TEL : 1533-4263</p>
        <p>FAX : 055-321-2176</p>
        <p>E-mail : office@yigongcoffee.com</p>
      </div>
      <div className="section2">
        <p>
          본 사이트의 모든 콘텐츠는 저작권법의 보호를 받는 바, 무단 전재, 복사,
          배포 등을 금합니다.
        </p>
        <p>Copyright(c) 2021. YigongCoffee. All Rights Reserved.</p>
      </div>
    </div>
  );
}
