export const bubbleTeaJuice = [
  {
    name: '흑당버블티',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/흑당버블티.jpg',
  },
  {
    name: '초코버블티',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/초코버블티.jpg',
  },
  {
    name: '로얄버블티',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/로얄버블티.jpg',
  },
  {
    name: '말차버블티',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/말차버블티.jpg',
  },
  {
    name: '딸기바나나',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/딸기바나나주스.jpg',
  },
  {
    name: '초코바나나',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/초코바나나주스.jpg',
  },
  {
    name: '딸기주스',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/딸기주스.jpg',
  },
  {
    name: '블루베리주스',
    imgUrl: '/img/menu/noneCoffee/BubbleTeaJuice/블루베리주스.jpg',
  },
];
