import { useState } from 'react';
import { createInquiry } from '../hooks/createInquiry';
import { AgreeModal } from './agreeModal';
import { useRecoilState } from 'recoil';
import { isAgreeModalOpenState } from '../recoil/atom';
import { sendEmail } from '../hooks/sendEmail';

export function InquiryModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    location: '',
    content: '',
  });
  const [dataCollectionAgreement, setDataCollectionAgreement] = useState(false);
  const [isAgreeModalOpen, setIsAgreeModalOpen] = useRecoilState(
    isAgreeModalOpenState
  );
  const openAgreeModal = () => setIsAgreeModalOpen(true);
  const closeAgreeModal = () => setIsAgreeModalOpen(false);

  // 모달을 닫기 위한 함수를 호출합니다.
  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  // 모달이 열려 있지 않으면 아무것도 렌더링하지 않습니다.
  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //전화번호 입력 폼
  const handleFormInputChange2 = (e) => {
    const { name, value } = e.target;
    let formattedValue = value.replace(/\D/g, ''); // 숫자만 남김

    if (formattedValue.length > 3 && formattedValue.length <= 7) {
      formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
        3
      )}`;
    } else if (formattedValue.length > 7) {
      formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
        3,
        7
      )}-${formattedValue.slice(7, 11)}`;
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // 개인정보 수집 동의 체크박스 변경 핸들러
  const handleDataCollectionAgreementChange = (event) => {
    setDataCollectionAgreement(event.target.checked);
  };
  // 폼을 제출할 때 호출되는 함수입니다.
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.phone ||
      !formData.location ||
      formData.phone.length < 13
    ) {
      alert('이름, 전화번호, 지역을 모두 입력해 주세요.');
      return;
    }

    if (!dataCollectionAgreement) {
      alert('개인정보 수집에 동의 부탁드립니다.');
      return;
    }

    createInquiry(
      formData.name,
      formData.phone,
      formData.location,
      '간편문의 입니다 연락 부탁드립니다.'
    );

    sendEmail(
      formData.name,
      formData.phone,
      formData.location,
      '간편문의 입니다 연락 부탁드립니다.'
    );

    alert('감사합니다.');
  };
  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal-content-inquiry">
        <label>문의하기</label>
        <form className="frm" id="frm" onSubmit={handleSubmit}>
          <div className="f_wrap animation">
            <ul>
              <li>
                <div className="d_wr">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="이름"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </li>
              <li>
                <div className="d_wr">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="연락처"
                    value={formData.phone}
                    onChange={(handleInputChange, handleFormInputChange2)}
                  />
                </div>
              </li>
              <li>
                <div className="d_wr">
                  <select
                    className="counselCompany"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                  >
                    <option value="defalut">지역을 선택해주세요</option>
                    <option>서울</option>
                    <option>인천</option>
                    <option>경기</option>
                    <option>강원</option>
                    <option>충북</option>
                    <option>충남</option>
                    <option>대전</option>
                    <option>경북</option>
                    <option>경남</option>
                    <option>부산</option>
                    <option>울산</option>
                    <option>대구</option>
                    <option>전북</option>
                    <option>전남</option>
                    <option>광주</option>
                    <option>제주</option>
                  </select>
                </div>
              </li>
            </ul>
            <div className="chk_w">
              <div className="chk">
                <input
                  type="checkbox"
                  checked={dataCollectionAgreement}
                  onChange={handleDataCollectionAgreementChange}
                />
                <span>
                  개인정보 수집 및 마케팅 활용 동의
                  {/* <Link to="/">[내용보기]</Link> */}
                  <p onClick={openAgreeModal} style={{ margin: '0' }}>
                    [내용보기]
                  </p>
                </span>
              </div>
            </div>
            <div className="submit">
              <button type="submit">문의하기</button>
              <button onClick={onClose} className="close-button">
                닫기
              </button>
            </div>
          </div>
        </form>
      </div>
      <AgreeModal isOpen={isAgreeModalOpen} onClose={closeAgreeModal} />
    </div>
  );
}
