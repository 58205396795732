/* eslint-disable no-unused-vars */
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/firestore';

export async function createInquiry(name, phone, location, content) {
  try {
    const createdTime = Timestamp.fromDate(new Date());
    const docRef = await addDoc(collection(db, 'inquiry'), {
      name: name,
      phone: phone,
      content: content,
      location: location,
      createdTime: createdTime,
    });
    // console.log("데이터가 생성되었습니다.: ", docRef.id);
    window.location.reload(); // 데이터 생성 후 페이지 리로딩
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}
