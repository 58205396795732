import React from 'react';
import { Link } from 'react-router-dom';
import { news } from '../../const/community/news';

export function News() {
  return (
    <div className="community">
      <div className="news">
        <div className="brand-title">
          <img src="/img/tab-commu2.png" alt="" />
          <div className="ment">
            <strong>커뮤니티</strong>
            {/* <p>무엇을 도와드릴까요</p> */}
          </div>
          <div className="sub-tabmenu">
            <div className="inner">
              <ul>
                <li>
                  <Link to="/notice">공지사항</Link>
                </li>
                <li className="on">
                  <Link to="/news">뉴스</Link>
                </li>
                {/* <li>
                  <Link to="/event">이벤트</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner">
          <div className="section1">
            <strong>뉴스</strong>
          </div>
          <div className="section2">
            {/* <div className="search">
              <span>
                <input placeholder="검색" />
              </span>
            </div> */}
            <div className="b_list">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>제목</th>
                    <th>날짜</th>
                  </tr>
                </thead>
                <tbody>
                  {news.map((item, index) => (
                    <tr>
                      <td>
                        <span>{news.length - index}</span>
                      </td>
                      <td>
                        <Link to={`/news/${item.id}`}>
                          <span>{item.title}</span>
                        </Link>
                      </td>
                      <td>{item.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className="paging">
              <div>
                <span className="on">1</span>
                <span>2</span>
                <span>3</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
