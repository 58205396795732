export const newMenu1 = [
  {
    name: '초코쉐이크볼',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/초코쉐이크볼.jpg',
  },
  {
    name: '밀크쉐이크볼',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/밀크쉐이크볼.jpg',
  },
  {
    name: '카라멜쉐이크볼',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/카라멜쉐이크볼.jpg',
  },
  {
    name: '팥빙수무디',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/팥빙수무디.jpg',
  },
  {
    name: '수박주스',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/수박주스.jpg',
  },
  {
    name: '1L보틀 아메리카노',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L아메리카노.jpg',
  },
  {
    name: '1L보틀 옛날커피',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L옛날커피.jpg',
  },
  {
    name: '1L보틀 카페라떼',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L카페라떼.jpg',
  },
  {
    name: '1L보틀 바닐라라떼',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L바닐라라떼.jpg',
  },
  {
    name: '1L보틀 돌체라떼',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L돌체라떼.jpg',
  },
  {
    name: '1L보틀 콜드브루',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L콜드브루.jpg',
  },
  {
    name: '1L보틀 꿀한라봉차',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L꿀한라봉차.jpg',
  },
  {
    name: '1L보틀 꿀한라봉블랙티',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L꿀한라봉블랙티.jpg',
  },
  {
    name: '1L보틀 복숭아아이스티',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L복숭아아이스티.jpg',
  },
  {
    name: '1L보틀 오미자아이스티',
    ice: true,
    imgUrl: '/img/menu/newmenu/1/1L오미자아이스티.jpg',
  },
  {
    name: '애플샤베트',
    imgUrl: '/img/menu/newmenu/1/애플샤베트.jpg',
  },
  {
    name: '청포도샤베트',
    imgUrl: '/img/menu/newmenu/1/청포도샤베트.jpg',
  },
  {
    name: '블루베리샤베트',
    imgUrl: '/img/menu/newmenu/1/블루베리샤베트.jpg',
  },
  {
    name: '한라봉샤베트',
    imgUrl: '/img/menu/newmenu/1/한라봉샤베트.jpg',
  },
  {
    name: '꿀자몽블랙티',
    imgUrl: '/img/menu/newmenu/1/꿀자몽블랙티.jpg',
  },
  {
    name: '꿀레몬블랙티',
    imgUrl: '/img/menu/newmenu/1/꿀레몬블랙티.jpg',
  },
  {
    name: '꿀한라봉블랙티',
    imgUrl: '/img/menu/newmenu/1/꿀한라봉블랙티.jpg',
  },
  {
    name: '한라봉스무디',
    imgUrl: '/img/menu/newmenu/1/한라봉스무디.jpg',
  },
  {
    name: '한라봉에이드',
    imgUrl: '/img/menu/newmenu/1/한라봉에이드.jpg',
  },
  {
    name: '핫꿀한라봉차',
    imgUrl: '/img/menu/newmenu/1/꿀한라봉차.jpg',
  },
  {
    name: '흑당버블티',
    imgUrl: '/img/menu/newmenu/1/흑당버블티.jpg',
  },
  {
    name: '초코버블티',
    imgUrl: '/img/menu/newmenu/1/초코버블티.jpg',
  },
  {
    name: '말차버블티',
    imgUrl: '/img/menu/newmenu/1/말차버블티.jpg',
  },
  {
    name: '로얄버블티',
    imgUrl: '/img/menu/newmenu/1/로얄버블티.jpg',
  },
  {
    name: '콜드브루',
    imgUrl: '/img/menu/newmenu/1/콜드브루.jpg',
  },
  {
    name: '콜드브루라떼',
    imgUrl: '/img/menu/newmenu/1/콜드브루라떼.jpg',
  },
  {
    name: '콜드브루아인슈페너',
    imgUrl: '/img/menu/newmenu/1/콜드브루슈페너.jpg',
  },
  {
    name: '딸기라떼',
    imgUrl: '/img/menu/newmenu/1/딸기라떼.jpg',
  },
  {
    name: '딸기주스',
    imgUrl: '/img/menu/newmenu/1/딸기주스.jpg',
  },
  {
    name: '딸기바나나주스',
    imgUrl: '/img/menu/newmenu/1/딸기바나나주스.jpg',
  },
];
