export const signature = [
  {
    name: '아몬드슈페너',
    imgUrl: '/img/menu/signature/아몬드슈페너.jpg',
  },
  {
    name: '솔트슈페너',
    imgUrl: '/img/menu/signature/솔트슈페너.jpg',
  },
  {
    name: '커피슈페너',
    imgUrl: '/img/menu/signature/커피슈페너.jpg',
  },
];
