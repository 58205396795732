export const TeaBlackTea = [
  {
    name: '캐모마일',
    imgUrl: '/img/menu/noneCoffee/Tea/캐모마일2.jpg',
  },
  {
    name: '얼그레이',
    imgUrl: '/img/menu/noneCoffee/Tea/얼그레이2.jpg',
  },
  {
    name: '페퍼민트',
    imgUrl: '/img/menu/noneCoffee/Tea/페퍼민트2.jpg',
  },
  {
    name: '루이보스',
    imgUrl: '/img/menu/noneCoffee/Tea/루이보스2.jpg',
  },
  {
    name: '꿀자몽차',
    imgUrl: '/img/menu/noneCoffee/Tea/꿀자몽2.jpg',
  },
  {
    name: '꿀레몬차',
    imgUrl: '/img/menu/noneCoffee/Tea/꿀레몬2.jpg',
  },
  {
    name: '꿀유자차',
    imgUrl: '/img/menu/noneCoffee/Tea/꿀유자2.jpg',
  },
  {
    name: '꿀생강차',
    imgUrl: '/img/menu/noneCoffee/Tea/꿀생강2.jpg',
  },
  {
    name: '꿀대추차',
    imgUrl: '/img/menu/noneCoffee/Tea/꿀대추2.jpg',
  },
  {
    name: '꿀한라봉차',
    imgUrl: '/img/menu/noneCoffee/Tea/꿀한라봉2.jpg',
  },
  {
    name: '꿀자몽블랙티',
    imgUrl: '/img/menu/noneCoffee/BlackTea/꿀자몽블랙티2.jpg',
  },
  {
    name: '꿀레몬블랙티',
    imgUrl: '/img/menu/noneCoffee/BlackTea/꿀레몬블랙티2.jpg',
  },
  {
    name: '꿀한라봉블랙티',
    imgUrl: '/img/menu/noneCoffee/BlackTea/꿀한라봉블랙티2.jpg',
  },
];
