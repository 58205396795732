import React from 'react';
import { Link } from 'react-router-dom';

export function Event() {
  return (
    <div className="community">
      <div className="event">
        <div className="brand-title">
          <img src="/img/tab-commu2.png" alt="" />
          <div className="ment">
            <strong>커뮤니티</strong>
            {/* <p>무엇을 도와드릴까요</p> */}
          </div>
          <div className="sub-tabmenu">
            <div className="inner">
              <ul>
                <li>
                  <Link to="/notice">공지사항</Link>
                </li>
                <li>
                  <Link to="/news">뉴스</Link>
                </li>
                <li className="on">
                  <Link to="/event">이벤트</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="inner">
          <div className="section1">
            <strong>이벤트</strong>
          </div>
          <div className="section2">
            <div className="list">
              <div className="content">
                <div className="tag on">
                  <span>진행중</span>
                </div>
                <div className="img">
                  <img src="/img/zzz.jpg" alt="" />
                </div>
                <div className="txt">
                  <strong>[23/11] 이벤트 제목</strong>
                  <p>기간 : 23.11.01~23.11.30</p>
                </div>
              </div>
              <div className="content">
                <div className="tag">
                  <span>종료</span>
                </div>
                <div className="img">
                  <img src="/img/zzz.jpg" alt="" />
                </div>
                <div className="txt">
                  <strong>[23/11] 이벤트 제목</strong>
                  <p>기간 : 23.11.01~23.11.30</p>
                </div>
              </div>
              <div className="content">
                <div className="tag">
                  <span>종료</span>
                </div>
                <div className="img">
                  <img src="/img/zzz.jpg" alt="" />
                </div>
                <div className="txt">
                  <strong>[23/11] 이벤트 제목이 길 때 어떻게 될까요</strong>
                  <p>기간 : 23.11.01~23.11.30</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
