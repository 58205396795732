/* eslint-disable react-hooks/rules-of-hooks */
import { useNavermaps } from 'react-naver-maps';

export function MarkerDatas() {
  const navermaps = useNavermaps();

  const markerDatas = [
    {
      id: 1,
      title: '흑석본점',
      address: '서울시 동작구 서달로151',
      call: '02-826-9194',
      imgUrl: 'img/mainShot/mainShot_1.jpg',
      lat: 37.50689,
      lng: 126.9615308,
      position: [37.5069677, 126.9615338],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5019677, 126.9615338),
        new navermaps.LatLng(37.5119677, 126.9615338)
      ),
    },
    {
      id: 2,
      title: '화명점',
      address: '부산 북구 금곡대로 285번길 29',
      call: '051-364-0320',
      imgUrl: '/img/mainShot/mainShot_2.jpg',
      lat: 35.23376,
      lng: 129.011896,
      position: [35.2338835, 129.0118436],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(35.2288835, 129.0116436),
        new navermaps.LatLng(35.2388835, 129.0116436)
      ),
    },
    {
      id: 3,
      title: '화곡점',
      address: '서울 강서구 등촌로5길 8 1층',
      call: '0507-1447-2012',
      imgUrl: '/img/mainShot/mainShot_3.jpg',
      lat: 37.5333867,
      lng: 126.8630812,
      position: [37.5333867, 126.8630812],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5283867, 126.8630812),
        new navermaps.LatLng(37.5383867, 126.8630812)
      ),
    },
    {
      id: 4,
      title: '서면점',
      address: '부산 부산진구 새싹로29번길 17 1, 2층 101, 201호',
      call: '051-914-2770',
      imgUrl: '/img/mainShot/mainShot_4.jpg',
      lat: 35.16009243,
      lng: 129.0560086,
      position: [35.16009243, 129.0518086],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(35.15509243, 129.0560086),
        new navermaps.LatLng(35.16509243, 129.0560086)
      ),
    },
    {
      id: 5,
      title: '연희점',
      address: '서울 서대문구 연희로 85 제101호',
      call: '0507-1497-2999',
      imgUrl: '/img/mainShot/mainShot_5.jpg',
      lat: 37.5660005,
      lng: 126.83580076,
      position: [37.5659795, 126.92993],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5610022, 126.9296646),
        new navermaps.LatLng(37.5710022, 126.9296646)
      ),
    },
    {
      id: 6,
      title: '충정로점',
      address: '서울 서대문구 경기대로 26-20 1층 101호, 102호',
      call: '0507-1362-2089',
      imgUrl: '/img/mainShot/mainShot_6.jpg',
      lat: 37.5616531,
      lng: 126.9627117,
      position: [37.5616431, 126.9626917],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5566498, 126.9624517),
        new navermaps.LatLng(37.5666498, 126.9624517)
      ),
    },
    {
      id: 7,
      title: '노량진점',
      address: '서울 동작구 노량진로 156 1층',
      call: '0507-1384-5066',
      imgUrl: '/img/mainShot/mainShot_7.jpg',
      lat: 37.5134972,
      lng: 126.9433956,
      position: [37.5133952, 126.9432956],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5084997, 126.9431203),
        new navermaps.LatLng(37.5184997, 126.9431203)
      ),
    },
    {
      id: 8,
      title: '등촌점',
      address: '서울 강서구 공항대로 61길 20 101동 1층',
      call: '070-4116-6599',
      imgUrl: '/img/mainShot/mainShot_8.jpg',
      lat: 37.5518414,
      lng: 126.8657403,
      position: [37.5518114, 126.8657933],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5513414, 126.8657403),
        new navermaps.LatLng(37.5523414, 126.8657403)
      ),
    },
    {
      id: 9,
      title: '이수역점',
      address: '서울 동작구 동작대로27길 8 1층 2호',
      call: '0507-1453-2034',
      imgUrl: '/img/mainShot/mainShot_9.jpg',
      lat: 37.4876331,
      lng: 126.9815668,
      position: [37.4876331, 126.9815768],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.4826331, 126.9815668),
        new navermaps.LatLng(37.4926331, 126.9815668)
      ),
    },
    {
      id: 10,
      title: '봉천점',
      address: '서울 관악구 장군봉1길 34 1층',
      call: '0507-1369-4702',
      imgUrl: '/img/mainShot/mainShot_12.jpg',
      lat: 37.4816931,
      lng: 126.9419,
      position: [37.4816531, 126.9419382],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.4811931, 126.9419),
        new navermaps.LatLng(37.4821931, 126.9419)
      ),
    },
    {
      id: 11,
      title: '신길점',
      address: '서울 영등포구 도신로 234 1층',
      call: '070-4647-0503',
      imgUrl: '/img/mainShot/mainShot_11.jpg',
      lat: 37.5120841,
      lng: 126.9174983,
      position: [37.5119841, 126.9173983],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5115841, 126.9174983),
        new navermaps.LatLng(37.5125841, 126.9174983)
      ),
    },
    {
      id: 12,
      title: '염창점',
      address: '서울 양천구 목동중앙북로16길 1층',
      call: '070-4647-4787',
      imgUrl: '/img/mainShot/mainShot_13.jpg',
      mapUrl: 'https://kko.to/4zeba7natz',
      lat: 37.5431,
      lng: 126.8714,
      position: [37.5431, 126.8714],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5426, 126.8714),
        new navermaps.LatLng(37.5436, 126.8714)
      ),
    },
    {
      id: 13,
      title: '내발산점',
      address: '서울 강서구 강서로 299 1층 102호',
      call: '02-6958-7123',
      imgUrl: '/img/mainShot/mainShot_14.jpg',
      mapUrl: 'https://kko.to/4zeba7natz',
      lat: 37.55255,
      lng: 126.836,
      position: [37.55255, 126.836],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.552, 126.836),
        new navermaps.LatLng(37.553, 126.836)
      ),
    },
    {
      id: 14,
      title: '사당점',
      address: '서울 동작구 사당로16길 44 1층',
      call: '0507-1428-7866',
      imgUrl: '/img/mainShot/mainShot_15.jpg',
      mapUrl: 'https://kko.to/Cs7dpX8uTf',
      lat: 37.4818,
      lng: 126.9724,
      position: [37.4818, 126.9724],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.4823, 126.9724),
        new navermaps.LatLng(37.4813, 126.9724)
      ),
    },
    {
      id: 15,
      title: '신월점',
      address: '서울 양천구 월정로 169 1층',
      call: '070-7766-2020',
      imgUrl: '/img/mainShot/mainShot_16.jpg',
      mapUrl: 'https://kko.to/CptmS4tnpr',
      lat: 37.5337,
      lng: 126.8358,
      position: [37.5337, 126.8358],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.5332, 126.8358),
        new navermaps.LatLng(37.5342, 126.8358)
      ),
    },
    {
      id: 16,
      title: '구로중앙점',
      address: '서울 구로구 도림로 79 1층',
      call: '02-6341-5642',
      imgUrl: '/img/mainShot/mainShot_17.jpg',
      mapUrl: 'https://kko.to/d7f14w40-M',
      lat: 37.4901,
      lng: 126.892,
      position: [37.4901, 126.892],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.4849, 126.8922),
        new navermaps.LatLng(37.4949, 126.8922)
      ),
    },
    {
      id: 17,
      title: '가락시장점',
      address: '서울특별시 송파구 가락동 80 1층',
      call: '-',
      imgUrl: '/img/mainShot/mainShot_18.png',
      mapUrl: 'https://kko.to/d7f14w40-M',
      // lat: 37.4901,
      // lng: 126.892,
      position: [37.4901, 126.892],
      location: new navermaps.LatLngBounds(
        new navermaps.LatLng(37.4849, 126.8922),
        new navermaps.LatLng(37.4949, 126.8922)
      ),
    },
    // {
    //   id: 15,
    //   title: '구로중앙점',
    //   address: '서울 구로구 도림로 79 1층',
    //   call: '02-6341-5642',
    //   imgUrl: '/img/mainShot/comingsoon.jpg',
    //   mapUrl: 'https://kko.to/d7f14w40-M',
    //   lat: 37.4901,
    //   lng: 126.892,
    //   position: [37.4901, 126.892],
    //   location: new navermaps.LatLngBounds(
    //     new navermaps.LatLng(37.4849, 126.8922),
    //     new navermaps.LatLng(37.4949, 126.8922)
    //   ),
    // },
  ];

  return markerDatas;
}
