export const newMenuPop = [
{
    name: '쉐이크볼',
    imgUrl: 'img/menu/newmenu/pop쉐이크볼.jpg',
},
{
    name: '팥빙수무디',
    imgUrl: 'img/menu/newmenu/pop팥빙수무디.jpg',
},
{
    name: '수박주스',
    imgUrl: 'img/menu/newmenu/pop수박주스.jpg',
},
{
    name: '샌드위치',
    imgUrl: 'img/menu/newmenu/pop샌드위치.jpg',
},
{
    name: '바틀',
    imgUrl: 'img/menu/newmenu/pop바틀.jpg',
},
{
    name: '샤베트',
    imgUrl: 'img/menu/newmenu/pop샤베트.jpg',
},
{
    name: '블랙티',
    imgUrl: 'img/menu/newmenu/pop블랙티.png',
},
{
    name: '한라봉',
    imgUrl: 'img/menu/newmenu/pop한라봉.png',
},
{
    name: '버블티',
    imgUrl: 'img/menu/newmenu/pop버블티.png',
},
{
    name: '콜드브루',
    imgUrl: 'img/menu/newmenu/pop콜드브루.png',
},
{
    name: '딸기',
    imgUrl: 'img/menu/newmenu/pop딸기.png',
},
]