export const news = [
  {
    id: 1,
    title:
      '제주 감성 프랜차이즈 카페 ‘이공커피’, 신메뉴 ‘시그니처 샌드위치’ 4종 출시',
    date: '2023-08-30',
    imgUrl: '/img/news/1.jpg',
    context: (
      <p>
        제주 감성 인테리어 카페 브랜드 '이공커피'에서 신메뉴 신선한 재료로 속이
        꽉 찬 ‘시그니처 샌드위치’ 4종을 출시했다.
        <br />
        <br />
        이번 신메뉴인 시그니처 샌드위치 4종은 △닭가슴살 클럽 △할라피뇨 불고기
        △튜나 △에그 베이컨 4종으로 구성되어 있으며, 각각 3,800원(닭가슴살 클럽,
        할라피뇨 불고기), 4,200원(튜나, 에그 베이컨)으로 합리적인 가격에 만나볼
        수 있다.
        <br />
        <br />
        이공커피는 그동안 벤티사이즈 아메리카노를 1,500원이라는 저렴한 가격으로
        소비자들에게 제공하며 브랜드 인지도와 만족도를 높여왔는데, 이번 신메뉴
        출시로 인해 한층 더 사랑을 받을 것으로 예상된다.
        <br />
        <br />
        이공커피 관계자는 “저렴한 가격이라고 품질도 저렴한 것이 아니라, 신선한
        재료를 아낌없이 사용하여 만든 샌드위치이니 고객님들이 꼭 맛보셨으면
        좋겠다”며, “신메뉴 노력에 많은 연구를 기울였고 앞으로도 꾸준한 연구를
        통해 가맹점주님들과 본사의 상생을 위해 노력할 것이다”라고 전했다.
        <br />
        <br />
        한편 신메뉴 ‘시그니처 샌드위치’는 이공커피 전국매장과 공식
        인스타그램에서 확인할 수 있다.
        <br />
        <br />
        출처 : 빌리어즈 <br />
        주소 : https://www.thebilliards.kr/news/articleView.html?idxno=21750
      </p>
    ),
  },
];
