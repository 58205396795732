import { Map, MapMarker } from 'react-kakao-maps-sdk';

export default function KakaoMap() {
  return (
    <Map
      center={{ lat: 37.64685, lng: 126.91575 }} // 지도의 중심 좌표
      style={{ width: '500px', height: '400px' }} // 지도 크기
      level={3} // 지도 확대 레벨
    >
      <MapMarker position={{ lat: 37.64685, lng: 126.91575 }}></MapMarker>
    </Map>
  );
}
