import React from 'react';
import { Link } from 'react-router-dom';

export function Cost() {
  return (
    <div className="cost">
      <div className="brand-title">
        <img src="/img/tab-franchise1.png" alt="" />
        <div className="ment">
          <strong>가맹안내</strong>
          {/* <p>너도 창업 할 수 있다</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/procedure">가맹절차</Link>
              </li>
              <li className="on">
                <Link to="/cost">개설비용</Link>
              </li>
              <li>
                <Link to="/advantage">경쟁력</Link>
              </li>
              <li>
                <Link to="/inquiry">가맹문의</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>개설 비용</strong>
        </div>

        <div className="section2 fadein">
          <table>
            <thead>
              <tr>
                <th>항 목</th>
                <th>내 용</th>
                <th colSpan={2}>비 용</th>
                <th>비 고</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  인테리어 외부간판,
                  <br /> 조명
                </td>
                <td>
                  바닥공사, 벽체공사, 설비(배관류), 조명공사, 샷시, 바카운터{' '}
                  <br />
                  돌담공사(외벽1면, 바카운터 하부), 간판공사, 외벽체
                </td>
                <td colSpan={2}>40,000,000</td>
                <td>
                  *10평 기준 추가비용
                  <br />
                  *평당 180만원
                </td>
              </tr>
              <tr>
                <td>집기류</td>
                <td>
                  커피머신 1대, 자동그라인더 1대, 제빙기 1대, 블렌더 2대,
                  <br />
                  25박스냉장고 1대, 음료냉장고 1대, 의탁자 4조(추가별도),
                  <br />
                  온정수기 1대, 테이블 냉장고 1대, 바리스타용품, 순간온수기 1대
                </td>
                <td colSpan={2}>25,000,000</td>
                <td></td>
              </tr>
              <tr>
                <td>가구</td>
                <td>테이블, 의자 (기본제공 4set 외 추가 입고 분)</td>
                <td>
                  테이블 <br />
                  의자
                </td>
                <td style={{ textAlign: 'right' }}>
                  120,000 <br />
                  60,000
                </td>
                <td></td>
              </tr>
              <tr>
                <td>교육비</td>
                <td>
                  기술교육, 가맹점 운영 교육, 레시피교육(자료제공),관리교육
                </td>
                <td colSpan={2} style={{ textDecoration: 'line-through' }}>
                  3,000,000
                </td>
                <td style={{ color: 'red' }}>한시적 면제</td>
              </tr>
              <tr>
                <td>가맹비</td>
                <td>브랜드 사용권 및 지역상권보장, know how제공 </td>
                <td colSpan={2} style={{ textDecoration: 'line-through' }}>
                  5,000,000
                </td>
                <td style={{ color: 'red' }}>한시적 면제</td>
              </tr>
              <tr>
                <td>로열티</td>
                <td>슈퍼바이저 출장 및 신메뉴개발, POP제작, 사후 관리 비용</td>
                <td colSpan={2}>월 100,000</td>
                <td></td>
              </tr>
              <tr>
                <td>외벽 1면 추가 </td>
                <td>현장에 따라 가격결정 됨</td>
                <td colSpan={2}></td>
                <td></td>
              </tr>
              <tr>
                <td>화장실 추가비</td>
                <td>현장에 따라 가격결정 됨</td>
                <td colSpan={2}></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">합 계</td>
                <td colSpan={3}>65,000,000 (VAT 별도)</td>
              </tr>
            </tfoot>
          </table>

          {/* <img src="/img/cost.jpg" alt="" /> */}
          <div className="ment">
            <h4>
              별도 사항 : 철거, 전기증설, 오토템핑기, 어닝, 냉난방기, 쇼케이스,
              CCTV, 오디오
            </h4>

            <p>
              * 상기 견적은 예시(10평 기준)입니다. (모든 비용은 VAT 별도
              금액입니다)
            </p>
            <p>
              * 실제 비용은 점포 상황 및 요청사항에 따라 견적이 변동될 수
              있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
