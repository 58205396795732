import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { InquiryModal } from './components/InquiryModal';
import {
  isInquiryModalOpenState,
  isStoreFindModalOpenState,
} from './recoil/atom';
import { useRecoilState } from 'recoil';
import { StoreFindModal } from './components/storeFindModal';

export function Header() {
  const [isOn, setIsOn] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const location = useLocation(); // Get access to the location object
  const [isInquiryModalOpen, setInquiryModalOpen] = useRecoilState(
    isInquiryModalOpenState
  );
  const [isStoreFindModalOpen, setStoreFindModalOpen] = useRecoilState(
    isStoreFindModalOpenState
  );

  const openInquiryModal = () => setInquiryModalOpen(true);
  const closeInquiryModal = () => setInquiryModalOpen(false);
  const openStoreFindModal = () => setStoreFindModalOpen(true);
  const closeStoreFindModal = () => setStoreFindModalOpen(false);

  const pathName = window.location.pathname;

  useEffect(() => {
    // This effect will run when the location changes
    setIsOn(false); // Close the menu when the location changes
    setExpandedMenu(null);
  }, [location]); // Depend on location to re-run this effect

  // 'hm' 요소를 클릭했을 때 호출되는 함수
  const toggleClass = () => {
    setIsOn(!isOn); // isOn 상태를 토글합니다.
    setIsMenuVisible(!isMenuVisible);
  };

  // 현재 확장된 메뉴 아이템의 상태를 null로 초기화합니다.
  const [expandedMenu, setExpandedMenu] = useState(null);

  // 메뉴 아이템 클릭 핸들러
  const handleMenuClick = (menuName) => {
    // 현재 확장된 메뉴가 다시 클릭되면 닫고, 그렇지 않으면 열립니다.
    setExpandedMenu(expandedMenu === menuName ? null : menuName);
  };
  return (
    <div className="header">
      <div className="inner">
        {/* <img className="logo1" src="/img/logo3.png" alt="" /> */}
        <div className="logo2">
          <Link to="/">
            <img src="/img/logo4.png" alt="Cafe Logo" />
          </Link>
        </div>

        <div className={`header-menu-box`}>
          <div
            className="header-menu"
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
          >
            <div
              className="menu-bg"
              style={{
                height: '200px',
                paddingTop: '0px',
                marginTop: '0px',
                paddingBottom: '0px',
                marginBottom: '0px',
              }}
            />
            <li>
              <Link to="/main">이공커피</Link>
              <ol
                style={{
                  height: '160px',
                  paddingTop: '20px',
                  marginTop: '0px',
                  paddingBottom: '20px',
                  marginBottom: '0px',
                }}
              >
                <li>
                  <Link to="/main">이로운공간</Link>
                </li>
                <li>
                  <Link to="/bi">BI</Link>
                </li>
                <li>
                  <Link to="/intro">오시는 길</Link>
                </li>
              </ol>
            </li>
            <li>
              <Link to="/procedure">가맹안내</Link>
              <ol
                style={{
                  height: '160px',
                  paddingTop: '20px',
                  marginTop: '0px',
                  paddingBottom: '20px',
                  marginBottom: '0px',
                }}
              >
                <li>
                  <Link to="/procedure">가맹절차</Link>
                </li>
                <li>
                  <Link to="/cost">개설비용</Link>
                </li>
                <li>
                  <Link to="/advantage">경쟁력</Link>
                </li>
                <li>
                  <Link to="/inquiry">가맹문의</Link>
                </li>
              </ol>
            </li>
            <li>
              <Link to="/newmenu">메뉴소개</Link>
              <ol
                style={{
                  height: '160px',
                  paddingTop: '20px',
                  marginTop: '0px',
                  paddingBottom: '20px',
                  marginBottom: '0px',
                }}
              >
                <li>
                  <Link to="/newmenu">신메뉴</Link>
                </li>
                <li>
                  <Link to="/allmenu">전체메뉴</Link>
                </li>
              </ol>
            </li>
            <li>
              <Link to="/store">매장</Link>
              <ol
                style={{
                  height: '160px',
                  paddingTop: '20px',
                  marginTop: '0px',
                  paddingBottom: '20px',
                  marginBottom: '0px',
                }}
              >
                <li>
                  <Link to="/store">매장소개</Link>
                </li>
                <li>
                  <Link to="/store-find">매장찾기</Link>
                </li>
              </ol>
            </li>
            <li>
              <Link to="/notice">커뮤니티</Link>
              <ol
                style={{
                  height: '160px',
                  paddingTop: '20px',
                  marginTop: '0px',
                  paddingBottom: '20px',
                  marginBottom: '0px',
                }}
              >
                <li>
                  <Link to="/notice">공지사항</Link>
                </li>
                <li>
                  <Link to="/news">뉴스</Link>
                </li>
                {/* <li>
                  <Link to="/event">이벤트</Link>
                </li> */}
              </ol>
            </li>
          </div>
        </div>
        <div className={`header-menu-mob ${isOn ? 'on' : ''}`}>
          <ul>
            <li>
              <span
                className="menu-item"
                onClick={() => handleMenuClick('이공커피')}
              >
                이공커피
              </span>
              <ol
                className={`sub-menu ${
                  expandedMenu === '이공커피' ? 'active' : ''
                }`}
              >
                <li>
                  <Link to="/main">이로운공간</Link>
                </li>
                <li>
                  <Link to="/bi">BI</Link>
                </li>
                <li>
                  <Link to="/intro">오시는 길</Link>
                </li>
              </ol>
            </li>
            <li>
              <span
                className="menu-item"
                onClick={() => handleMenuClick('가맹안내')}
              >
                가맹안내
              </span>
              <ol
                className={`sub-menu ${
                  expandedMenu === '가맹안내' ? 'active' : ''
                }`}
              >
                <li>
                  <Link to="/procedure">가맹절차</Link>
                </li>
                <li>
                  <Link to="/cost">개설비용</Link>
                </li>
                <li>
                  <Link to="/advantage">경쟁력</Link>
                </li>
                <li>
                  <Link to="/inquiry">가맹문의</Link>
                </li>
              </ol>
            </li>
            <li>
              <span
                className="menu-item"
                onClick={() => handleMenuClick('메뉴소개')}
              >
                메뉴소개
              </span>
              <ol
                className={`sub-menu ${
                  expandedMenu === '메뉴소개' ? 'active' : ''
                }`}
              >
                <li>
                  <Link to="/newmenu">신메뉴</Link>
                </li>
                <li>
                  <Link to="/allmenu">전체메뉴</Link>
                </li>
              </ol>
            </li>
            <li>
              <span
                className="menu-item"
                onClick={() => handleMenuClick('매장')}
              >
                매장
              </span>
              <ol
                className={`sub-menu ${
                  expandedMenu === '매장' ? 'active' : ''
                }`}
              >
                <li>
                  <Link to="/store">매장소개</Link>
                </li>
                <li>
                  <Link to="/store-find">매장찾기</Link>
                </li>
              </ol>
            </li>

            <li>
              <span
                className="menu-item"
                onClick={() => handleMenuClick('커뮤니티')}
              >
                커뮤니티
              </span>
              <ol
                className={`sub-menu ${
                  expandedMenu === '커뮤니티' ? 'active' : ''
                }`}
              >
                <li>
                  <Link to="/notice">공지사항</Link>
                </li>
                <li>
                  <Link to="/news">뉴스</Link>
                </li>
                {/* <li>
                  <Link to="/event">이벤트</Link>
                </li> */}
              </ol>
            </li>
          </ul>
        </div>
        {/* <div className="sns">
          <Link to="https://www.instagram.com/yigong_coffee_official/">
            <img src="/img/instagram.png" alt="" style={{ width: "20px" }} />
          </Link>
          <Link to="https://www.facebook.com/profile.php?id=100091987514445/">
            <img src="/img/facebook.png" alt="" style={{ width: "20px" }} />
          </Link>
          <Link to="https://www.instagram.com/yigong_coffee_official/">
            <img src="/img/blog.png" alt="" style={{ width: "20px" }} />
          </Link>
        </div> */}

        <div className={`hm ${isOn ? 'on' : ''}`} onClick={toggleClass}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="sideFlow">
          <ul>
            {pathName !== '/store-find' ? (
              <li onClick={openStoreFindModal}>
                <button>
                  <i>
                    <img src="/img/sideFlowIcon1.png" alt="" />
                  </i>
                  <strong>매장찾기</strong>
                </button>
              </li>
            ) : (
              <li
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <button>
                  <i>
                    <img src="/img/arrow_up.png" alt="" />
                  </i>
                </button>
              </li>
            )}
            {pathName !== '/inquiry' ? (
              <li onClick={openInquiryModal}>
                <button>
                  <i>
                    <img src="/img/sideFlowIcon2.png" alt="" />
                  </i>
                  <strong>가맹문의</strong>
                </button>
              </li>
            ) : (
              <li
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <button>
                  <i>
                    <img src="/img/arrow_up.png" alt="" />
                  </i>
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
      <InquiryModal isOpen={isInquiryModalOpen} onClose={closeInquiryModal} />
      <StoreFindModal
        isOpen={isStoreFindModalOpen}
        onClose={closeStoreFindModal}
      />
    </div>
  );
}
