import { signInWithEmailAndPassword } from 'firebase/auth';

export async function login(auth, email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    // 로그인 성공
    const user = userCredential.user;
    console.log('로그인 성공:', user);
    // sessionStorage.setItem("accessToken", user.accessToken);
    // sessionStorage.setItem("refreshToken", user.stsTokenManager.refreshToken);
    // sessionStorage.setItem("uid", user.uid);

    // 토큰을 서버 측 쿠키에 저장하는 로직 필요 (예: 서버 API 호출)

    // 페이지 리다이렉션
    // window.location.href = "/admin";
  } catch (error) {
    // 로그인 실패
    console.error(`로그인 실패: ${error.code} - ${error.message}`);
    switch (error.code) {
      case 'auth/invalid-login-credentials':
        alert('유효하지 않은 정보입니다.');
        break;
      case 'auth/too-many-requests':
        alert('너무 많은 요청으로 계정이 제한됩니다. 잠시 후 시도해주세요');
        break;
      default:
        alert('로그인에 실패했습니다. 다시 시도해주세요.');
    }
  }
}

// /* eslint-disable react-hooks/rules-of-hooks */
// import { signInWithEmailAndPassword } from "firebase/auth";

// export function login(auth, email, password) {
//   signInWithEmailAndPassword(auth, email, password)
//     .then((userCredential) => {
//       // 로그인 성공
//       const user = userCredential.user;
//       console.log("로그인 성공:", user);
//       sessionStorage.setItem("accessToken", user.accessToken);
//       sessionStorage.setItem("uid", user.uid);
//       // window.location.href = "/admin";
//     })
//     .catch((error) => {
//       // 로그인 실패
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       console.log(`로그인 실패: ${errorCode} - ${errorMessage}`);
//       if (errorCode === "auth/invalid-login-credentials") {
//         alert("유효하지 않은 정보입니다.");
//       }
//       if (errorCode === "auth/too-many-requests") {
//         alert("너무 많은 요청으로 계정이 제한됩니다. 잠시 후 시도해주세요");
//       }
//     });
// }
