export const newMenu2 = [
  {
    name: '크룽지',
    imgUrl: '/img/menu/newmenu/2/달콤크룽지.jpg',
  },
  {
    name: '튜나 샌드위치',
    imgUrl: '/img/menu/newmenu/2/튜나샌드위치.jpg',
  },
  {
    name: '에그베이컨 샌드위치',
    imgUrl: '/img/menu/newmenu/2/에그베이컨샌드위치.jpg',
  },
  {
    name: '닭가슴살클럽 샌드위치',
    imgUrl: '/img/menu/newmenu/2/닭가슴살클럽샌드위치.jpg',
  },
  {
    name: '할라피뇨 샌드위치',
    imgUrl: '/img/menu/newmenu/2/할라피뇨불고기샌드위치.jpg',
  },
  {
    name: '딸기우유 마카롱',
    imgUrl: '/img/menu/newmenu/2/딸기우유마카롱.jpg',
  },
  {
    name: '리츠치즈 마카롱',
    imgUrl: '/img/menu/newmenu/2/리츠치즈마카롱.jpg',
  },
  {
    name: '마약옥수수 마카롱',
    imgUrl: '/img/menu/newmenu/2/마약옥수수마카롱.jpg',
  },
  {
    name: '바삭퐁라떼 마카롱',
    imgUrl: '/img/menu/newmenu/2/바삭퐁마카롱.jpg',
  },
  {
    name: '순수우유 마카롱',
    imgUrl: '/img/menu/newmenu/2/순수우유마카롱.jpg',
  },
  {
    name: '플레인요거트 마카롱',
    imgUrl: '/img/menu/newmenu/2/요거트마카롱.jpg',
  },
  {
    name: '카페모카 마카롱',
    imgUrl: '/img/menu/newmenu/2/카페모카마카롱.jpg',
  },
  {
    name: '쿠앤크 마카롱',
    imgUrl: '/img/menu/newmenu/2/쿠앤크마카롱.jpg',
  },
];
