export const bestMenu = [
  {
    name: '꿀한라봉블랙티',
    imgUrl: '/img/menu/bestMenu/꿀한라봉블랙티.png',
  },
  {
    name: '딸기라떼',
    imgUrl: '/img/menu/bestMenu/딸기라떼.png',
  },
  {
    name: '말차라떼',
    imgUrl: '/img/menu/bestMenu/말차라떼.png',
  },
  {
    name: '바닐라라떼',
    imgUrl: '/img/menu/bestMenu/바닐라라떼.png',
  },
  {
    name: '솔트슈페너',
    imgUrl: '/img/menu/bestMenu/솔트슈페너.png',
  },
  {
    name: '커피슈페너',
    imgUrl: '/img/menu/bestMenu/커피슈페너.png',
  },
  {
    name: '흑당버블티',
    imgUrl: '/img/menu/bestMenu/흑당버블티.png',
  },
];
