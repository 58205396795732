/* eslint-disable no-unused-vars */
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/firestore';

export async function sendEmail(name, phone, location, content) {
  try {
    // const createdTime = Timestamp.fromDate(new Date());
    const docRef = await addDoc(collection(db, 'send_email'), {
      from: 'yigongcoffeedev@gmail.com',
      to: ['gpgp08@naver.com', 'office@yigongcoffee.com'],
      message: {
        subject: '이공커피 홈페이지에 새로운 문의가 작성되었습니다.',
        text: 'This is the plaintext section of the email body.',
        html: `
        <table style="width:100%; border-collapse: collapse;">
          <tr>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #ab9f8f; width: 10%;">이름</th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #ab9f8f; width: 10%;">지역</th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #ab9f8f; width: 15%;">번호</th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #ab9f8f;">내용</th>
          </tr>
          <tr>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: 10%;">${name}</td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: 10%;">${location}</td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: 15%;">${phone}</td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${content}</td>
          </tr>
        </table>
      `,
      },
    });
    // window.location.reload(); // 데이터 생성 후 페이지 리로딩
    console.log('send Email');
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}
