import React from 'react';
import './App.css';
import './Common.css';
import './Layout.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from './Header';
import { Dashboard } from './Dashboard';
import { BrandItroduce } from './pages/section1/BrandIntroduce';
import { Bi } from './pages/section1/Bi';
import { Intro } from './pages/section1/Intro';
import { Procedure } from './pages/section2/Procedure';
import { Cost } from './pages/section2/Cost';
import { Advantage } from './pages/section2/Advantage';
import { Inquiry } from './pages/section2/Inquiry';
import { Coffee } from './pages/section3/Coffee';
import { Allmenu } from './pages/section3/Allmenu';
import { Newmenu } from './pages/section3/Newmenu';
import { Store } from './pages/section4/Store';
import { Notice } from './pages/section5/Notice';
import { News } from './pages/section5/News';
import { Event } from './pages/section5/Event';
import { Footer } from './Footer';
import { Admin } from './sectionAdmin/Admin';
import { StoreFind } from './pages/section4/StoreFind';
import { NoticeCont } from './pages/section5/NoticeCont';
import { NewsCont } from './pages/section5/NewCont';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />

          <Route path="/main" element={<BrandItroduce />} />
          <Route path="/Bi" element={<Bi />} />
          <Route path="/intro" element={<Intro />} />

          <Route path="/procedure" element={<Procedure />} />
          <Route path="/cost" element={<Cost />} />
          <Route path="/advantage" element={<Advantage />} />
          <Route path="/inquiry" element={<Inquiry />} />

          <Route path="/newmenu" element={<Newmenu />} />
          <Route path="/coffee" element={<Coffee />} />
          <Route path="/allmenu" element={<Allmenu />} />
          <Route path="/allmenu/:id" element={<Allmenu />} />

          <Route path="/store" element={<Store />} />
          <Route path="/store-find" element={<StoreFind />} />

          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:id" element={<NoticeCont />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsCont />} />
          <Route path="/event" element={<Event />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
