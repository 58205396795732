export const blended = [
  {
    id: 1,
    name: '쿠앤크스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/쿠앤크스무디.jpg',
  },
  {
    id: 2,
    name: '초코스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/초코스무디.jpg',
  },
  {
    id: 3,
    name: '자바칩스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/자바칩스무디.jpg',
  },
  {
    id: 4,
    name: '말차스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/말차스무디.jpg',
  },
  {
    id: 5,
    name: '한라봉스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/한라봉스무디.jpg',
  },
  {
    id: 6,
    name: '자몽스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/자몽스무디.jpg',
  },
  {
    id: 7,
    name: '청포도스무디',
    ice: true,
    imgUrl: '/img/menu/noneCoffee/Smoothie/청포도스무디.jpg',
  },
  {
    id: 8,
    name: '플레인요거트스무디',
    ice: true,
    imgUrl: '/img/menu//yogurt/플레인요거트스무디.jpg',
  },
  {
    id: 9,
    name: '딸기요거트스무디',
    ice: true,
    imgUrl: '/img/menu//yogurt/딸기요거트스무디.jpg',
  },
  {
    id: 10,
    name: '블루베리요거트스무디',
    ice: true,
    imgUrl: '/img/menu//yogurt/블루베리요거트스무디.jpg',
  },
  {
    id: 11,
    name: '밀크쉐이크',
    ice: true,
    imgUrl: '/img/menu/shake/밀크쉐이크.jpg',
  },
  {
    id: 12,
    name: '밀크커피쉐이크',
    ice: true,
    imgUrl: '/img/menu/shake/밀크커피쉐이크.jpg',
  },
  {
    id: 13,
    name: '딸기밀크쉐이크',
    ice: true,
    imgUrl: '/img/menu/shake/딸기밀크쉐이크.jpg',
  },
  {
    id: 14,
    name: '블루베리밀크쉐이크',
    ice: true,
    imgUrl: '/img/menu/shake/블루베리밀크쉐이크.jpg',
  },
  {
    id: 15,
    name: '애플샤베트',
    ice: true,
    imgUrl: '/img/menu/sherbet/애플샤베트.jpg',
  },
  {
    id: 16,
    name: '블루베리샤베트',
    ice: true,
    imgUrl: '/img/menu/sherbet/블루베리샤베트.jpg',
  },
  {
    id: 17,
    name: '한라봉샤베트',
    ice: true,
    imgUrl: '/img/menu/sherbet/한라봉샤베트.jpg',
  },
  {
    id: 18,
    name: '청포도샤베트',
    ice: true,
    imgUrl: '/img/menu/sherbet/청포도샤베트.jpg',
  },
];
