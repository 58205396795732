export const allStores = [
  {
    id: 1,
    title: '흑석본점',
    address: '서울시 동작구 서달로151',
    call: '02-826-9194',
    imgUrl: '/img/mainShot/mainShot_1.jpg',
    mapUrl: 'https://kko.to/jmDsBMPGfa',
  },
  {
    id: 2,
    title: '화명점',
    address: '부산 북구 금곡대로 285번길 29',
    call: '051-364-0320',
    imgUrl: '/img/mainShot/mainShot_2.jpg',
    mapUrl: 'https://kko.to/73_dDRPhlD',
  },
  {
    id: 3,
    title: '화곡점',
    address: '서울 강서구 등촌로5길 8 1층',
    call: '0507-1447-2012',
    imgUrl: '/img/mainShot/mainShot_3.jpg',
    mapUrl: 'https://kko.to/Qe5lNRN5Oe',
  },
  {
    id: 4,
    title: '서면점',
    address: '부산 부산진구 새싹로29번길 17 1, 2층 101, 201호',
    call: '051-914-2770',
    imgUrl: '/img/mainShot/mainShot_4.jpg',
    mapUrl: 'https://kko.to/G_buqKhl8-',
  },
  {
    id: 5,
    title: '연희점',
    address: '서울 서대문구 연희로 85 제101호',
    call: '0507-1497-2999',
    imgUrl: '/img/mainShot/mainShot_5.jpg',
    mapUrl: 'https://kko.to/iz60YYnom8',
  },
  {
    id: 6,
    title: '충정로점',
    address: '서울 서대문구 경기대로 26-20 1층 101호, 102호',
    call: '0507-1362-2089',
    imgUrl: '/img/mainShot/mainShot_6.jpg',
    mapUrl: 'https://kko.to/8UjKjLWeBz',
  },
  {
    id: 7,
    title: '노량진점',
    address: '서울 동작구 노량진로 156 1층',
    call: '0507-1384-5066',
    imgUrl: '/img/mainShot/mainShot_7.jpg',
    mapUrl: 'https://kko.to/3mLnhp6zE8',
  },
  {
    id: 8,
    title: '등촌점',
    address: '서울 강서구 공항대로 61길 20 101동 1층',
    call: '070-4116-6599',
    imgUrl: '/img/mainShot/mainShot_8.jpg',
    mapUrl: 'https://kko.to/KgbQOUsbmS',
  },
  {
    id: 9,
    title: '이수역점',
    address: '서울 동작구 동작대로27길 8 1층2호',
    call: '0507-1453-2034',
    imgUrl: '/img/mainShot/mainShot_9.jpg',
    mapUrl: 'https://kko.to/94y4tos2QM',
  },
  {
    id: 10,
    title: '봉천점',
    address: '서울 관악구 장군봉1길 34 1층',
    call: '0507-1369-4702',
    imgUrl: '/img/mainShot/mainShot_12.jpg',
    mapUrl: 'https://kko.to/4zeba7natz',
  },
  {
    id: 11,
    title: '신길점',
    address: '서울 영등포구 도신로 234 1층',
    call: '070-4647-0503',
    imgUrl: '/img/mainShot/mainShot_11.jpg',
    mapUrl: 'https://kko.to/IBD7tI0QxS',
  },
  {
    id: 12,
    title: '염창점',
    address: '서울 양천구 목동중앙북로16길',
    call: '070-4647-4787',
    imgUrl: '/img/mainShot/mainShot_13.jpg',
    mapUrl: 'https://kko.to/4zeba7natz',
  },
  {
    id: 13,
    title: '내발산점',
    address: '서울 강서구 강서로 299 1층 102호',
    call: '02-6958-7123',
    imgUrl: '/img/mainShot/mainShot_14.jpg',
    mapUrl: 'https://kko.to/4zeba7natz',
  },
  {
    id: 14,
    title: '사당점',
    address: '서울 동작구 사당로16길 44',
    call: '-',
    imgUrl: '/img/mainShot/mainShot_13.jpg',
    mapUrl: '-',
  },
];
