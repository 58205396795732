import React from 'react';
import { Link } from 'react-router-dom';

export function BrandItroduce() {
  return (
    <div className="brandIntroduce">
      <div className="brand-title">
        <img src="/img/tab-yigong.png" alt="" />
        <div className="ment">
          <strong>이공커피</strong>
          {/* <p>합리적인 가격과 높은 품질의 커피</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li className="on">
                <Link to="/main">이로운공간</Link>
              </li>
              <li>
                <Link to="/bi">BI</Link>
              </li>
              <li>
                <Link to="/intro">오시는 길</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>이로운공간</strong>
        </div>
        <div className="section4">
          <img src="/img/bg/bg4.jpeg" alt="" />
          <div className="ment1">
            <p>利</p>
            <span>​이로울 이</span>
          </div>
          <div className="ment2">
            <p>空</p>
            <span>빌 공</span>
          </div>
          <div className="ment3">
            <p>당신과 함께, 당신의 행복한 하루를</p>
            <span className="big">이로운 공간 </span>
            <span>이공커피</span>
            <span>가 함께 하겠습니다.</span>
          </div>
        </div>
        <div className="section2">
          <img src="/img/intro1_1.png" alt="" />
          <p>
            머릿속 한 켠의 아담한 작은 추억을
            <br />
            되새겨 드리고자 하는 신념 하나로 이공커피를 오픈하게 되었습니다.
            <br />
            <br />
            제주의 아름다운 분위기를 잠시나마 느끼시며
            <br />
            이공커피에서 행복한 음료 한잔과 함께
            <br />
            하루를맞이 할 수 있도록 항상 노력하는 이공커피가 되겠습니다.
            <br />
            <br />
            이공커피 일동
          </p>
        </div>
        <div className="section5">
          <div className="left">
            <img src="/img/bg/bg5.jpg" alt="" />
          </div>
          <div className="right">
            <p>
              이공커피는 <span>우수한 품질 </span>의 커피와 음료를 <br />
              <span>합리적인 가격으로 BIG SIZE </span> 컵에 제공하고 있습니다.
              <br />
              <br />
              <span>더 크고, 더 맛있고, 더 착한 </span> 음료로 <br />
              오래 즐길 수 있도록 하였습니다.
            </p>
          </div>
        </div>
        <div className="section3">
          <img src="/img/intro2.png" alt="" />
          <p>
            <span>입안 가득 </span>
            퍼지는
            <br />
            <span>고소함, 바디감, 단맛</span>
            <br />
            신선한 <span>클래식 원두</span>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
