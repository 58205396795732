export const noneCoffee = [
  {
    name: '딸기라떼(Only Ice)',
    imgUrl: '/img/menu/noneCoffee/딸기라떼2.jpg',
  },
  {
    name: '초코라떼',
    imgUrl: '/img/menu/noneCoffee/초코라떼2.jpg',
  },
  {
    name: '말차라떼',
    imgUrl: '/img/menu/noneCoffee/말차라떼2.jpg',
  },
  {
    name: '토피넛라떼',
    imgUrl: '/img/menu/noneCoffee/토피넛라떼2.jpg',
  },
  {
    name: '흑당라떼',
    imgUrl: '/img/menu/noneCoffee/흑당라떼2.jpg',
  },
  {
    name: '밀크티라떼',
    imgUrl: '/img/menu/noneCoffee/로얄밀크티라떼2.jpg',
  },
  {
    name: '미숫가루라떼',
    imgUrl: '/img/menu/noneCoffee/미숫가루라떼2.jpg',
  },
  {
    name: '고구마라떼',
    imgUrl: '/img/menu/noneCoffee/고구마라떼2.jpg',
  },
];
