import React, { useEffect, useState } from 'react';
import { auth } from '../firebase/firestore';
import { login } from '../hooks/login';
import { getInquiryData } from '../hooks/getInquiry';
import { logout } from '../hooks/logout';

export function Admin() {
  const [postData, setPostData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    pw: '',
  });

  // 각 input의 변경사항을 state에 반영하는 함수입니다.
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // 폼을 제출할 때 호출되는 함수입니다.
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.id || !formData.pw) {
      alert('아이디, 비밀번호를 모두 입력해 주세요.');
      return;
    } else {
      login(auth, formData.id, formData.pw);
    }

    // 콘솔에 폼 데이터를 출력합니다.
    console.log('Form Data:', formData);
  };

  useEffect(() => {
    // getPostData 함수를 호출하여 데이터를 가져오고 state에 저장
    const fetchData = async () => {
      try {
        const result = await getInquiryData();
        setPostData(result);
        // console.log(result);
      } catch (error) {
        console.error('데이터 가져오기 오류:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // 사용자가 로그인한 상태
        console.log('로그인 상태:', user);
        setIsLoggedIn(true);
      } else {
        // 사용자가 로그아웃한 상태
        // console.log("로그아웃 상태");
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe(); // 컴포넌트가 언마운트될 때 청취를 중단
  }, []);

  function convertTimestamp(timestamp) {
    // 타임스탬프를 Date 객체로 변환
    const date = new Date(timestamp);

    // 연, 월, 일, 시, 분, 초 가져오기
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // 연월일시간 문자열 반환
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div className="admin">
      <div className="inner">
        <div className="section1">
          <strong>회원페이지</strong>
        </div>
        {!isLoggedIn && (
          <div className="section2">
            <div>
              <form className="frm" id="frm" onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <input
                      type="text"
                      name="id"
                      id="id"
                      placeholder="아이디를 입력해주세요"
                      value={formData.id}
                      onChange={handleInputChange}
                    />
                  </li>
                  <li>
                    <input
                      type="password"
                      name="pw"
                      id="pw"
                      placeholder="비밀번호를 입력해주세요"
                      value={formData.pw}
                      onChange={handleInputChange}
                    />
                  </li>
                </ul>
                <div>
                  <button type="submit">
                    <p>로그인</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {isLoggedIn && (
          <div className="section3">
            <div className="subTitle">
              <strong>문의내역</strong>
            </div>
            <div className="b_list">
              <button className="logout" onClick={() => logout()}>
                로그아웃
              </button>
              {/* <button onClick={handleSendEmail}>이메일 발송</button> */}
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>일자</th>
                    <th>이름</th>
                    <th>지역</th>
                    <th>번호</th>
                    <th>내용</th>
                  </tr>
                </thead>
                <tbody>
                  {postData.map((item, index) => (
                    <tr className="news" key={index}>
                      <td>
                        <span>{index + 1}</span>
                      </td>
                      <td>
                        <span>
                          {convertTimestamp(item.createdTime.seconds * 1000)}
                        </span>
                      </td>
                      <td>
                        <span>{item.name}</span>
                      </td>
                      <td>
                        <span>{item.location}</span>
                      </td>
                      <td>
                        <span>{item.phone}</span>
                      </td>
                      <td>
                        <span>{item.content}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className="paging">
                <div>
                  <span className="on">1</span>
                  <span>2</span>
                  <span>3</span>
                </div>
              </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
