import React from 'react';
import { Link } from 'react-router-dom';
import { FiPhoneCall } from 'react-icons/fi';
import { TbMapSearch } from 'react-icons/tb';
import { BsCashCoin } from 'react-icons/bs';
import { PiHandshake } from 'react-icons/pi';
import { MdConstruction } from 'react-icons/md';
import { VscCoffee } from 'react-icons/vsc';
import { IoStorefrontOutline } from 'react-icons/io5';
import { LiaDoorOpenSolid } from 'react-icons/lia';
import { MdOutlineManageSearch } from 'react-icons/md';

export function Procedure() {
  return (
    <div className="procedure">
      <div className="brand-title">
        <img src="/img/tab-franchise1.png" alt="" />
        <div className="ment">
          <strong>가맹안내</strong>
          {/* <p>너도 창업 할 수 있다</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li className="on">
                <Link to="/procedure">가맹절차</Link>
              </li>
              <li>
                <Link to="/cost">개설비용</Link>
              </li>
              <li>
                <Link to="/advantage">경쟁력</Link>
              </li>
              <li>
                <Link to="/inquiry">가맹문의</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>가맹 절차</strong>
        </div>
        <div className="section2">
          <div className="contents-wrap">
            <div className="content fadeIn visible">
              <FiPhoneCall size={35} color="#533c34" />
              <div>STEP 01</div>
              <h4>가맹문의</h4>
              <p>가맹점 문의 / 상담</p>
            </div>
            <div className="content  fadeIn visible">
              <TbMapSearch size={35} color="#533c34" />
              <div>STEP 02</div>
              <h4>상권조사</h4>
              <p>상권분석 / 점포선정</p>
            </div>
            <div className="content  fadeIn visible">
              <BsCashCoin size={35} color="#533c34" />
              <div>STEP 03</div>
              <h4>실측 및 개설금액 확정</h4>
              <p>선정된 후보지 실측 / 창업 비용 확정</p>
            </div>
            <div className="content  fadeIn visible">
              <PiHandshake size={35} color="#533c34" />
              <div>STEP 04</div>
              <h4>가맹계약체결</h4>
              <p>가맹계약체결 / 계약금 입금</p>
            </div>
            <div className="content  fadeIn visible">
              <MdConstruction size={35} color="#533c34" />
              <div>STEP 05</div>
              <h4>인테리어 공사</h4>
              <p>일정 및 세부사항 협의 / 공사 진행</p>
            </div>
            <div className="content  fadeIn visible">
              <VscCoffee size={35} color="#533c34" />
              <div>STEP 06</div>
              <h4>가맹점 교육 진행</h4>
              <p>메뉴 교육 진행 / 서비스 교육 진행</p>
            </div>
            <div className="content  fadeIn visible">
              <IoStorefrontOutline size={35} color="#533c34" />
              <div>STEP 07</div>
              <h4>오픈준비</h4>
              <p>초도물품 검수 / 최종점검 및 오픈 시뮬레이션</p>
            </div>
            <div className="content  fadeIn visible">
              <LiaDoorOpenSolid size={35} color="#533c34" />
              <div>STEP 08</div>
              <h4>점포 오픈</h4>
              <p>슈퍼바이저 파견 / 오픈 행사</p>
            </div>
            <div className="content  fadeIn visible">
              <MdOutlineManageSearch size={35} color="#533c34" />
              <div>STEP 09</div>
              <h4>매장관리</h4>
              <p>운영관리</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
