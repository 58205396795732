import { atom } from 'recoil';

export const mapperState = atom({
  key: 'mapperState',
  default: null,
});

export const isInquiryModalOpenState = atom({
  key: 'isInquiryModalOpenState',
  default: false,
});

export const isStoreFindModalOpenState = atom({
  key: 'isStoreFindModalOpenState',
  default: false,
});

export const isAgreeModalOpenState = atom({
  key: 'isAgreeModalOpenState',
  default: false,
});
