export const coldBrew = [
  {
    id: 1,
    name: '콜드브루',
    ice: true,
    imgUrl: '/img/menu/coldbrew/콜드브루.jpg',
  },
  {
    id: 2,
    name: '콜드브루슈페너',
    ice: true,
    imgUrl: '/img/menu/coldbrew/콜드브루슈페너.jpg',
  },
  {
    id: 3,
    name: '콜드브루라떼',
    ice: true,
    imgUrl: '/img/menu/coldbrew/콜드브루라떼.jpg',
  },
  {
    id: 4,
    name: '콜드브루디카페인',
    ice: true,
    imgUrl: '/img/menu/coldbrew/콜드브루.jpg',
  },
  {
    id: 5,
    name: '콜드브루슈페너디카페인',
    ice: true,
    imgUrl: '/img/menu/coldbrew/콜드브루슈페너.jpg',
  },
  {
    id: 6,
    name: '콜드브루라떼디카페인',
    ice: true,
    imgUrl: '/img/menu/coldbrew/콜드브루라떼.jpg',
  },
];
