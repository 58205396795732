import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase/firestore';

// users 전체 데이터 가져오는 함수
export async function getInquiryData() {
  let result = [];

  const q = query(collection(db, 'inquiry'), orderBy('createdTime'));

  // 'users' 컬렉션의 모든 문서들을 가져옴
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // 가져온 모든 문서들을 확인
    result.push({ ...doc.data(), id: doc.id });
  });

  return result;
}
