export const notice = [
  {
    id: 12,
    title: '이공커피 공식 홈페이지 리뉴얼 안내',
    date: '2024-03-19',
    imgUrl: '/img/notice/12.png',
  },
  {
    id: 11,
    title: '이공커피 가을 신메뉴 코코볼 3종 출시',
    date: '2023-10-09',
    imgUrl: '/img/notice/11.jpg',
    context: '',
  },
  {
    id: 10,
    title: `이공커피 '샌드위치 4종' 출시`,
    date: '2023-08-07',
    imgUrl: '/img/notice/10.jpg',
    context: '',
  },
  {
    id: 9,
    title: `이공커피 신메뉴 '팥빙수무디' 출시`,
    date: '2023-07-18',
    imgUrl: '/img/notice/9.jpg',
    context: '',
  },
  {
    id: 8,
    title: `이공커피 여름 신메뉴 '수박주스' 출시`,
    date: '2023-06-17',
    imgUrl: '/img/notice/8.jpg',
    context: '',
  },
  {
    id: 7,
    title: '이공커피 10호점 오픈',
    date: '2023-06-04',
    imgUrl: '/img/notice/7.jpg',
    context: '',
  },
  {
    id: 6,
    title: `이공커피 대용량 음료 '1L 보틀' 출시`,
    date: '2023-05-23',
    imgUrl: '/img/notice/6.jpg',
    context: '',
  },
  {
    id: 5,
    title: `이공커피 베이커리 신메뉴 '뚱카롱' 출시`,
    date: '2023-04-26',
    imgUrl: '/img/notice/5.jpg',
    context: '',
  },
  {
    id: 4,
    title: '이공커피 전메뉴 리뉴얼',
    date: '2023-01-01',
    imgUrl: '/img/notice/4.jpg',
    context: '',
  },
  {
    id: 3,
    title: '이공커피 직영 2호점 오픈',
    date: '2022-07-22',
    imgUrl: '/img/notice/3.jpg',
    context: '',
  },
  {
    id: 2,
    title: '이공커피 5호점 오픈',
    date: '2022-07-04',
    imgUrl: '/img/notice/2.jpg',
    context: '',
  },
  {
    id: 1,
    title: '이공커피 브랜드 런칭',
    date: '2021-04-28',
    imgUrl: '/img/notice/1.jpg',
    context: '',
  },
];
