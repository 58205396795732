export const dessert = [
  {
    id: 1,
    name: '크룽지',
    imgUrl: '/img/menu/newmenu/2/달콤크룽지.jpg',
  },
  {
    id: 2,
    name: '튜나 샌드위치',
    imgUrl: '/img/menu/newmenu/2/튜나샌드위치.jpg',
  },
  {
    id: 3,
    name: '에그베이컨 샌드위치',
    imgUrl: '/img/menu/newmenu/2/에그베이컨샌드위치.jpg',
  },
  {
    id: 4,
    name: '닭가슴살클럽 샌드위치',
    imgUrl: '/img/menu/newmenu/2/닭가슴살클럽샌드위치.jpg',
  },
  {
    id: 5,
    name: '할라피뇨불고기 샌드위치',
    imgUrl: '/img/menu/newmenu/2/할라피뇨불고기샌드위치.jpg',
  },
  {
    id: 6,
    name: '딸기우유마카롱',
    imgUrl: '/img/menu/newmenu/2/딸기우유마카롱.jpg',
  },
  {
    id: 7,
    name: '리츠치즈마카롱',
    imgUrl: '/img/menu/newmenu/2/리츠치즈마카롱.jpg',
  },
  {
    id: 8,
    name: '마약옥수수마카롱',
    imgUrl: '/img/menu/newmenu/2/마약옥수수마카롱.jpg',
  },
  {
    id: 9,
    name: '바삭퐁마카롱',
    imgUrl: '/img/menu/newmenu/2/바삭퐁마카롱.jpg',
  },
  {
    id: 10,
    name: '순수우유마카롱',
    imgUrl: '/img/menu/newmenu/2/순수우유마카롱.jpg',
  },
  {
    id: 11,
    name: '플레인요거트마카롱',
    imgUrl: '/img/menu/newmenu/2/요거트마카롱.jpg',
  },
  {
    id: 12,
    name: '카페모카마카롱',
    imgUrl: '/img/menu/newmenu/2/카페모카마카롱.jpg',
  },
  {
    id: 13,
    name: '쿠앤크마카롱',
    imgUrl: '/img/menu/newmenu/2/쿠앤크마카롱.jpg',
  },

  {
    id: 14,
    name: '순수우유케이크',
    imgUrl: '/img/menu/dessert/bakery/순수우유케이크.jpg',
  },
  {
    id: 15,
    name: '바스크치즈케이크',
    imgUrl: '/img/menu/dessert/bakery/바스크치즈케이크.jpg',
  },
  {
    id: 16,
    name: '단호박타르트',
    imgUrl: '/img/menu/dessert/bakery/단호박타르트.jpg',
  },
  {
    id: 17,
    name: '애플타르트',
    imgUrl: '/img/menu/dessert/bakery/애플타르트.jpg',
  },
  {
    id: 18,
    name: '블루베리머핀',
    imgUrl: '/img/menu/dessert/bakery/블루베리머핀.jpg',
  },
  {
    id: 19,
    name: '초코머핀',
    imgUrl: '/img/menu/dessert/bakery/더블초코머핀.jpg',
  },
  {
    id: 20,
    name: '녹차파운드',
    imgUrl: '/img/menu/dessert/bakery/녹차파운드.jpg',
  },
  {
    id: 21,
    name: '오렌지파운드',
    imgUrl: '/img/menu/dessert/bakery/오렌지파운드.jpg',
  },
  {
    id: 22,
    name: '앙버터다쿠아즈',
    imgUrl: '/img/menu/dessert/bakery/앙버터다쿠아즈.jpg',
  },
  {
    id: 23,
    name: '쇼콜라다쿠아즈',
    imgUrl: '/img/menu/dessert/bakery/쇼콜라다쿠아즈.jpg',
  },
  {
    id: 24,
    name: '핑크솔트다쿠아즈',
    imgUrl: '/img/menu/dessert/bakery/핑크솔트다쿠아즈.jpg',
  },
  {
    id: 25,
    name: '그린티다쿠아즈',
    imgUrl: '/img/menu/dessert/bakery/그린티다쿠아즈.jpg',
  },
];
