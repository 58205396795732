import { Link, useParams } from 'react-router-dom';
import { news } from '../../const/community/news';

export const NewsCont = () => {
  let { id } = useParams();
  const post = news.find((item) => item.id === parseInt(id));

  return (
    <div className="community">
      <div className="newsCont">
        <div className="brand-title">
          <img src="/img/tab-commu2.png" alt="" />
          <div className="ment">
            <strong>커뮤니티</strong>
            {/* <p>무엇을 도와드릴까요</p> */}
          </div>
          <div className="sub-tabmenu">
            <div className="inner">
              <ul>
                <li>
                  <Link to="/notice">공지사항</Link>
                </li>
                <li className="on">
                  <Link to="/news">뉴스</Link>
                </li>
                {/* <li>
              <Link to="/event">이벤트</Link>
            </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner">
          <div className="section1">
            <strong>뉴스</strong>
          </div>
          <div className="section2">
            <h1>{post.title}</h1>
            <div style={{ textAlign: 'right' }}>
              <span style={{ paddingRight: '30px' }}>{post.date}</span>
              <span>보도자료</span>
            </div>
          </div>
          <div className="section3">
            <img src={post.imgUrl} alt="" />
            {post.context}
          </div>
        </div>
      </div>
    </div>
  );
};
