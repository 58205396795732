import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

export function Advantage() {
  const [isVisibleSection2, setVisibleSection2] = useState(false);

  const nameRef = useRef();
  const section2Ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === nameRef.current) {
        } else if (entry.target === section2Ref.current) {
          setVisibleSection2(entry.isIntersecting);
        }
      });
    });

    const currentNameRef = nameRef.current;
    const currentSection2Ref = section2Ref.current;

    if (currentNameRef) {
      observer.observe(currentNameRef);
    }
    if (currentSection2Ref) {
      observer.observe(currentSection2Ref);
    }

    return () => {
      if (currentNameRef) {
        observer.unobserve(currentNameRef);
      }
      if (currentSection2Ref) {
        observer.unobserve(currentSection2Ref);
      }
    };
  }, []);

  return (
    <div className="advantage">
      <div className="brand-title">
        <img src="/img/tab-franchise1.png" alt="" />
        <div className="ment">
          <strong>가맹안내</strong>
          {/* <p>너도 창업 할 수 있다</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/procedure">가맹절차</Link>
              </li>
              <li>
                <Link to="/cost">개설비용</Link>
              </li>
              <li className="on">
                <Link to="/advantage">경쟁력</Link>
              </li>
              <li>
                <Link to="/inquiry">가맹문의</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>경쟁력</strong>
        </div>
        <div ref={section2Ref} className="section2">
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <img src="img/advantage/bg1.jpg" alt="" />
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <div>
              <strong>본사와 가맹점의 상생 파트너쉽</strong>
              <p>
                수평적인 상생 파트너쉽을 바탕으로 본사의 노하우와 체계적인 가맹
                관리 시스템으로 안정적인 운영을 위하여 처음부터 끝까지
                관리합니다.
              </p>
            </div>
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <strong>물류시스템 </strong>
            <p>
              본사의 고품질 원자재 공급과 신속한 대응 및 원활한 배송 시스템으로
              안정적인 매장 운영을 조성합니다.
            </p>
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <img src="img/advantage/bg2.jpg" alt="" />
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <img src="img/advantage/bg3.jpg" alt="" />
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <strong>누구나 할 수 있는 음료 제조</strong>
            <p>
              효율적인 동선과 반자동화 시스템, 그리고 간편한 레시피로 손쉽게
              음료를 제조함으로써 현장 중심의 교육을 통해 인력 관리를 효율적으로
              합니다.
            </p>
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <strong>수익의 극대화</strong>
            <p>
              소형매장, 낮은 인건비(매장 내 키오스크 도입), 간단한 레시피로 높은
              수익을 창출합니다.
            </p>
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <img src="img/advantage/bg4.jpg" alt="" />
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <img src="img/advantage/bg5.jpg" alt="" />
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <strong>차별화된 인테리어</strong>
            <p>
              공장에서 찍어낸듯한 프랜차이즈 인테리어가 아닌 유니크한 돌담
              포인트로 어디에나 스며드는 인테리어 컨셉을 제공합니다.
            </p>
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <strong>지속적인 메뉴 개발</strong>
            <p>
              본사 메뉴 개발팀의 체계적인 신메뉴 개발로 경쟁력을 강화합니다.
            </p>
          </div>
          <div
            className={`content fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
          >
            <img src="img/advantage/bg6.jpg" alt="" />
          </div>
        </div>
        {/* <div className="section3">
          "이공커피에서는 단순한 커피 한 잔 이상의 가치를 제공합니다.
          <br /> 우리는 탁월한 맛, 진정한 서비스, 그리고 휴식과 여유를 중요시
          여깁니다.
          <br /> 커피 한 잔과 함께 하는 여러분의 모든 순간이 기억에 남도록
          노력합니다.
          <br />
          이곳에서는, 커피가 단순한 음료가 아닌 삶의 일부가 됩니다."
        </div> */}
      </div>
    </div>
  );
}
