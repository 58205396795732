/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { allStores } from './const/allStores';
import { Link, useNavigate } from 'react-router-dom';
import { bestMenu } from './const/menu/bestMenu';
import { IoHomeOutline } from 'react-icons/io5';
// import { LiaHandRock } from 'react-icons/lia';
import { BsCashCoin } from 'react-icons/bs';
import { BsGraphUpArrow } from 'react-icons/bs';
export function Dashboard() {
  const [isVisibleName, setVisibleName] = useState(false);
  const [isVisibleSection2, setVisibleSection2] = useState(false);
  const grandOpen = allStores.slice(-4);
  const [extendedSlides, setExtendedSlides] = useState([]);

  const nameRef = useRef();
  const section2Ref = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === nameRef.current) {
          setVisibleName(entry.isIntersecting);
        } else if (entry.target === section2Ref.current) {
          setVisibleSection2(entry.isIntersecting);
        }
      });
    });

    if (nameRef.current) {
      observer.observe(nameRef.current);
    }
    if (section2Ref.current) {
      observer.observe(section2Ref.current);
    }

    return () => {
      if (nameRef.current) {
        observer.unobserve(nameRef.current);
      }
      if (section2Ref.current) {
        observer.unobserve(section2Ref.current);
      }
    };
  }, []);

  useEffect(() => {
    setExtendedSlides([...bestMenu, ...bestMenu.slice(0, 6)]);
  }, [bestMenu]);

  return (
    <div className="dashboard">
      <div className="section1">
        <img src="/img/bg/bgbg2.jpg" alt="배경 이미지" />
        <div
          ref={nameRef}
          className={`name fadeIn ${isVisibleName ? 'visible' : ''}`}
        >
          <p>내 일상 속의 안식처</p>
          <p>이로운 공간</p>
          <p>이공커피와 함께</p>
        </div>
      </div>
      <div
        ref={section2Ref}
        className={`section2 fadeIn ${isVisibleSection2 ? 'visible' : ''}`}
      >
        <div className="sub-title">
          <strong>이로운 가치</strong>
          <br />
          이공커피와 함께 평범한 일상을 특별한 순간으로
        </div>
        <div className="content-wrap">
          <div className="content">
            <IoHomeOutline
              size={50}
              color="#533c34"
              onClick={() => navigate('/main')}
            />
            <strong>이로운 공간</strong>
            <Link to="/main">바로가기</Link>
          </div>
          <div className="content">
            <BsGraphUpArrow
              size={50}
              color="#533c34"
              onClick={() => navigate('/advantage')}
            />
            <strong>경쟁력</strong>
            <Link to="/advantage">바로가기</Link>
          </div>
          <div className="content">
            <BsCashCoin
              size={50}
              color="#533c34"
              onClick={() => navigate('/cost')}
            />

            <strong>개설비용</strong>
            <Link to="/cost">바로가기</Link>
          </div>
        </div>
      </div>
      <div className="section3">
        <div className="sub-title">
          <strong>
            <span>이공커피</span>의 인기 메뉴를 소개합니다
          </strong>
        </div>
        <Swiper
          slidesPerView={7}
          spaceBetween={10}
          speed={1000}
          loop={true}
          autoplay={{ delay: 2000 }}
          modules={[Autoplay]}
          style={{ height: 'auto' }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {extendedSlides.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={item.imgUrl} alt={`section4-${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="overlay" />
        <div className="overlay2" />
      </div>
      <div className="section4">
        <div className="sub-title">
          <div className="left" />
          <div>
            <strong>이로운 공간</strong>
            <p>
              "<span>새롭게 찾아온 </span>이공커피를 만나보세요."
            </p>
          </div>
          <div className="right" />
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={1500}
          speed={1000}
          loop={true}
          autoplay={{ delay: 2000 }}
          modules={[Autoplay]}
          style={{ height: 'auto' }}
          effect="linear"
        >
          {grandOpen.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="contents">
                <div className="left">
                  <h1>GRAND OPEN</h1>
                  <strong className="title">{item.title}</strong>
                  <div>
                    <p>{item.address}</p>
                  </div>
                </div>
                <div className="right">
                  <img src={item.imgUrl} alt="section4" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
