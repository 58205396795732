import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RecoilRoot } from 'recoil';
import { NavermapsProvider } from 'react-naver-maps';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <NavermapsProvider ncpClientId="yhrfcbsod7">
    <Suspense fallback={<div>Loading Maps...</div>}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Suspense>
  </NavermapsProvider>
);
