/* eslint-disable no-undef */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import KakaoMap from './KakaoMap';

export function Intro() {
  const navigate = useNavigate();
  return (
    <div className="intro">
      <div className="brand-title">
        <img src="/img/tab-yigong.png" alt="" />
        <div className="ment">
          <strong>이공커피</strong>
          {/* <p>합리적인 가격과 높은 품질의 커피</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/main">이로운공간</Link>
              </li>
              <li>
                <Link to="/bi">BI</Link>
              </li>
              <li className="on">
                <Link to="/intro">오시는 길</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>오시는 길</strong>
        </div>
        <div className="section2">
          <div className="left">
            <div>
              <strong>이공컴퍼니 본사 오시는 길</strong>
              <p>경기도 고양시 덕양구 지정로 15, 301, 302호</p>
              <p>15 Jijeong-ro, Deogyang-gu, Goyang-si, Gyeonggi-do</p>

              <p>TEL. 1533-4263</p>
              <p>E-mail. office@yigongcoffee.com</p>
            </div>

            <div>
              <button
                className="mapLinkBtn"
                onClick={() =>
                  window.open('https://naver.me/x10MbiST', '_blank')
                }
              >
                네이버지도
              </button>
              <button
                className="mapLinkBtn"
                onClick={() =>
                  window.open('https://kko.to/z0QJKIeNY5', '_blank')
                }
              >
                카카오지도
              </button>
              <button
                className="mapLinkBtn"
                onClick={() => navigate('/inquiry')}
              >
                가맹문의 바로가기
              </button>
            </div>
          </div>
          <div className="right">
            <KakaoMap />
          </div>
        </div>
      </div>
    </div>
  );
}
