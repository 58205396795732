export const coffee = [
  {
    name: '아메리카노',
    imgUrl: '/img/menu/coffee/아메리카노2.jpg',
  },
  {
    name: '옛날커피',
    imgUrl: '/img/menu/coffee/옛날커피2.jpg',
  },
  {
    name: '카페라떼',
    imgUrl: '/img/menu/coffee/카페라떼2.jpg',
  },
  {
    name: '카푸치노(Only Hot)',
    imgUrl: '/img/menu/coffee/핫카푸치노.jpg',
  },

  {
    name: '바닐라라떼',
    imgUrl: '/img/menu/coffee/바닐라라떼2.jpg',
  },
  {
    name: '헤이즐넛라떼',
    imgUrl: '/img/menu/coffee/헤이즐넛라떼2.jpg',
  },
  {
    name: '카라멜마끼야또',
    imgUrl: '/img/menu/coffee/카라멜마끼야또2.jpg',
  },
  {
    name: '카페모카',
    imgUrl: '/img/menu/coffee/카페모카2.jpg',
  },
  {
    name: '돌체라떼',
    imgUrl: '/img/menu/coffee/돌체라떼2.jpg',
  },
  {
    name: '흑당카페라떼',
    imgUrl: '/img/menu/coffee/흑당카페라떼2.jpg',
  },
  {
    name: '달고나카페라떼',
    imgUrl: '/img/menu/coffee/달고나카페라떼2.jpg',
  },
];
