export function AgreeModal({ isOpen, onClose }) {
  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal"
      onClick={handleClickOutside}
      style={{ zIndex: '1002' }}
    >
      <div className="modal-content-agree">
        <strong>개인정보처리방침</strong>
        <span className="close" onClick={() => onClose()}>
          X
        </span>
        <p>
          개인정보 수집 및 이용에 대한 내용을 충분히 읽어보신 후 동의하여 주시기
          바랍니다. 개인정보 수집범위 : 이름, 이메일, 연락처(가맹문의 및
          상담자료 활용의 목적) <br />※ 해당내용의 이용자 개인정보는 원칙적으로
          개인정보의 수집 및 이용목적이 달성되면 지체없이 파기되며, 보유기간은
          최대 3년입니다.
        </p>
        <div className="content">
          <p>
            이공컴퍼니는 (이하 "회사"는) 고객님의 개인정보를 중요시하며,
            "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
            <br />
            회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가
            어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한
            조치가 취해지고 있는지 알려드립니다.
            <br />
            <br />■ 수집하는 개인정보 항목 및 수집방법 가. 수집하는 개인정보의
            항목 o 회사는 상담 서비스 신청 등을 위해 아래와 같은 개인정보를
            수집하고 있습니다. - 상담 서비스 신청 시 : 이름, 영문이름, 휴대폰
            번호, 주소, 이메일
            <br />
            <br />
            나. 수집방법 - 해당 교육신청 게시판 글 작성 <br />
            <br />■ 개인정보의 수집 및 이용목적
            <br />
            회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
            <br />
            o 교육 접수를 위한 연락처 활용
            <br />
            아리아센터 가입 상담, 가맹점 개설 상담, 지도자과정 등록 상담, 마케팅
            관련 상담 등 고객이 원하는 서비스 정보를 제공하기 위한 연락처 활용.
            <br />
            o 마케팅 및 광고에 활용
            <br />
            이벤트 등 광고성 정보 전달 , 접속 빈도 파악 또는 회원의 서비스
            이용에 대한 통계
            <br />
            <br />
            ■ 개인정보의 보유 및 이용기간 <br />
            원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
            지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로
            명시한 기간 동안 보존합니다.
            <br />
            <br />
            관련 법령에 의한 정보 보유 사유
            <br />
            전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
            의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한
            일정한 기간 동안 회원정보를 보관합니다.
            <br />
            o 소비자 불만 또는 분쟁처리에 관한 기록
            <br />
            -보존이유 : 전자상거래 등 에서의 소비자보호에 관한 법률
            <br />
            -보존기간 : 3년
            <br />
            o 로그 기록
            <br />
            -보존이유: 통신비밀보호법
            <br />
            -보존기간 : 3개월
            <br />
            <br />
            ■ 개인정보의 파기절차 및 방법 <br />
            회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
            정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.{' '}
            <br />
            o 파기절차
            <br />
            고객님이 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의
            경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호
            사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
            집니다.
            <br />
            별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는
            보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
            <br />
            <br />
            o 파기방법
            <br />
            전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
            방법을 사용하여 삭제합니다.
            <br />
            <br />
            ■ 개인정보 제공
            <br />
            회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다.
            다만, 아래의 경우에는 예외로 합니다.
            <br />
            o 이용자들이 사전에 동의한 경우
            <br />
            o 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
            방법에 따라 수사기관의 요구가 있는 경우
            <br />
            <br />
            ■ 이용자 및 법정대리인의 권리와 그 행사방법
            <br />
            o 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나
            수정할 수 있습니다.
            <br />
            o 이용자들의 개인정보 조회, 수정을 원하실 경우
            개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이
            조치하겠습니다.
            <br />
            o 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
            완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
            잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
            제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
            <br />
            o 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가
            수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그
            외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            <br />
            <br />
            ■ 개인정보에 관한 민원서비스 회사는 고객의 개인정보를 보호하고
            개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
            개인정보관리책임자를 지정하고 있습니다.
            <br />
            o 귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
            관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
            <br />
            o 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴
            것입니다.
            <br />
            o 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
            기관에 문의하시기 바랍니다.
            <br />
            개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
            <br />
            대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-2000)
            <br />
            경찰청 사이버안전국 (www.ctrc.go.kr/ 국번 없이 182)
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
