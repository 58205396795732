import React from 'react';
import { Link } from 'react-router-dom';
import { coffee } from '../../const/menu/coffee/coffee';
import { signature } from '../../const/menu/coffee/signature';
import { coldBrew } from '../../const/menu/coffee/coldbrew';

export function Coffee() {
  return (
    <div className="coffee">
      <div className="brand-title">
        <img src="/img/tab-menu1.png" alt="" />
        <div className="ment">
          <strong>메뉴</strong>
          {/* <p>합리적인 가격과 높은 품질의 커피</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/newmenu">신메뉴</Link>
              </li>
              <li className="on">
                <Link to="/coffee">커피</Link>
              </li>
              <li>
                <Link to="/allmenu">전체메뉴</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>커피</strong>
          <p>Coffee (HOT/ICE)</p>
        </div>
        <div className="section2">
          <div className="sub-title">
            <strong>시그니처</strong>
            <p>Signature (only ICE)</p>
          </div>
          <div className="content-wrap" style={{ justifyContent: 'center' }}>
            {signature.map((item) => (
              <div className="content" key={item.id}>
                <img src={item.imgUrl} alt="" />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="section3">
          <div className="sub-title">
            <strong>커피</strong>
            <p>Coffee (HOT/ICE)</p>
          </div>
          <div className="content-wrap">
            {coffee.map((item) => (
              <div className="content" key={item.id}>
                <img src={item.imgUrl} alt="" />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="section4">
          <div className="sub-title">
            <strong>콜드브루</strong>
            <p>ColdBrew (only ICE)</p>
          </div>
          <div className="content-wrap">
            {coldBrew.map((item) => (
              <div
                className="content"
                key={item.id}
                style={{ flex: '0 1 33%' }}
              >
                <img src={item.imgUrl} alt="" />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
