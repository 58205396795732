/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import { StoreMaps } from './StoreMaps';

export function StoreFind() {
  return (
    <div className="store">
      <div className="brand-title">
        <img src="/img/tab-store.png" alt="" />
        <div className="ment">
          <strong>매장</strong>
          {/* <p>합리적인 가격과 높은 품질의 커피</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/store">매장소개</Link>
              </li>
              <li className="on">
                <Link to="/store-find">매장찾기</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>매장</strong>
        </div>
        <div className="section4">
          <div className="sub-title">
            <strong>매장 찾기</strong>
          </div>
          <div className="content">
            <StoreMaps />
          </div>
        </div>
      </div>
    </div>
  );
}
