export const bottle = [
  {
    id: 1,
    name: '1L아메리카노',
    ice: true,
    imgUrl: '/img/menu/bottle/1L아메리카노.jpg',
  },
  {
    id: 2,
    name: '1L옛날커피',
    ice: true,
    imgUrl: '/img/menu/bottle/1L옛날커피.jpg',
  },
  {
    id: 3,
    name: '1L카페라떼 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L카페라떼.jpg',
  },
  {
    id: 4,
    name: '1L바닐라라떼 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L바닐라라떼.jpg',
  },
  {
    id: 5,
    name: '1L돌체라떼 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L돌체라떼.jpg',
  },
  {
    id: 6,
    name: '1L콜드브루 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L콜드브루.jpg',
  },
  {
    id: 7,
    name: '1L꿀한라봉차 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L꿀한라봉차.jpg',
  },
  {
    id: 8,
    name: '1L꿀한라봉블랙티 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L꿀한라봉블랙티.jpg',
  },

  {
    id: 9,
    name: '1L복숭아아이스티 ',
    ice: true,
    imgUrl: '/img/menu/bottle/1L복숭아아이스티.jpg',
  },
  {
    id: 10,
    name: '1L오미자아이스티',
    ice: true,
    imgUrl: '/img/menu/bottle/1L오미자아이스티.jpg',
  },
];
